<template>
  <div class="information-list">
    <div 
      class="information-list__item" 
      :class="{ showDivider }"
      v-for="(row, rowIndex) in list" 
      :key="rowIndex" 
      :style="row.width ? `width: ${row.width}` : ''"
    >
      <template v-for="(item, index) in row.children" >
        <div v-if="item.value === 'hide'">
          ----
        </div>
        <BaseDescription
          v-else
          :key="index" 
          :label="item.label"
        >
          <template v-if="item.slot">
            <slot :name="item.slot" />
          </template>
          <template v-else>{{ item.value || '----' }}</template>
        </BaseDescription>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    showDivider: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.information-list {
  display: flex;
  padding: 10px 15px 14px;
  border-radius: 5px;
  background-color: $--background-hover;
  .information-list__item {
    box-sizing: border-box;
    position: relative;
    padding-left: 30px;
    &.showDivider::after {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      right: 0;
      width: 1px;
      background: linear-gradient(180deg,#f5f7fa, #e3e3e3 53%, #f5f7fa);
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      &::after {
        display: none;
      }
    }
    >div + div {
      margin-top: 9px;
    }
  }
}
</style>