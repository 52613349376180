<!-- FPC订单信息 -->
<template>
  <div class="information">
    <div class="information__title">
      <i class="information__linker"></i>
      PCB
    </div>
    <div class="information__container">
      <InformationTop :data="topData" />
      <InformationList :list="list">
        <!-- 阻焊颜色 -->
        <div slot="color" class="information__color">
          <ColorBlock :color="data.color" />
          <span :style="colors[data.color]" class="information__color__text">
            {{ data.color }}
          </span>
          (字符：{{ data.charcolor }})
        </div>
        <!-- 补强 -->
        <div slot="reinforce" class="information__reinforce">
          <el-tooltip effect="dark" :content="reinforceFormat()" placement="top" :disabled="reinforceFormat() === '不需要'">
            <span class="information__reinforce__handler">{{ reinforceFormat() }}</span>
          </el-tooltip>
        </div>
      </InformationList>
      <InformationList :list="buttonList">
        <!-- 确认生产稿 -->
        <div slot="review_file" class="information__review_file">
          {{ { 0: '无需', 1: '需要(超时系统自动确认)', 2: '需要(不允许自动确认)' }[data.review_file] }}
        </div>
        <!-- 订单支付方式 -->
        <div slot="deduct_type" class="information__deduct_type">
          <span class="tips">{{ { 1: '自动扣款并确认', 2: '手动确认支付' }[data.deduct_type] }}</span>
          <template v-if="data.deduct_type == 1">
            (正负相差不超过<i>￥{{ data.deduct_limit }}</i>)
          </template>
        </div>
      </InformationList>
    </div>
  </div>
</template>

<script>
import InformationTop from './top'
import InformationList from './list'
import ColorBlock from '@@/components/colorBlock/index'

import { COLORS } from '@@/utils/constant'
import { getLabels, getOptionsLabel, getUnitMap } from '@@/utils/getFields'

export default {
  props: {
    type: {
      type: String,
      validator: function (value) {
        return ['PCB', 'FPC'].includes(value)
      }
    },
    data: {
      type: Object,
      default: () => ({})
    },
    // 是否是PCBA返单
    isPcbaReturn: {
      type: Boolean,
      default: false
    }
  },
  components: {
    InformationTop,
    InformationList,
    ColorBlock
  },
  data() {
    return {
      colors: COLORS,
      getOptionsLabelFields: {
        PCB: [
          'blind', 'board_brand', 'units', 'overlay_type', 'beveledge', 'impendance', 'bankong', 'baobian',
          'user_stamp', 'cross_board', 'paper', 'report_type', 'review_file', 'copper', 'insidecopper'
        ],
        FPC: [
          'material', 'units', 'back_gum_type', 'electromagnetic_membrane', 'conducting_resin', 'impendance',
          'cross_board', 'report_type', 'copper', 'insidecopper', 'overlay_type'
        ]
      }
    }
  },
  computed: {
    boardType() {
      return { PCB: 'FR-4', FPC: 'FPC' }[this.type]
    },
    labels() {
      return getLabels(this.boardType)
    },
    fieldsFormat() {
      const data = this.data
      const getValueByKey = key => getOptionsLabel(this.boardType, key, data[key])
      const units = getUnitMap(this.boardType)
      const hasUnit = key => `${data[key]}${units[key]}`
      // 需要获取选项对应的值的字段
      const valuesByOptions = this.getOptionsLabelFields[this.type].reduce((acc, key) => {
          acc[key] = getValueByKey(key)
          return acc
        }, {})
      // PCB FPC可以统一处理的字段
      const format = {
        blayer: `${data.blayer} 层`,
        length: this.isPcbaReturn ? `${data.blength}x${data.bwidth} cm` : `${data.length}x${data.width} cm`,
        layout: data.units == 2 ? `${data.layoutx}x${data.layouty} Pcs` : 'hide',
        sidewidth: data.units != 3 ? (data.sidedirection === '无' ? '无' : `${getOptionsLabel('FR-4', 'sidewidth', data.sidedirection)}，${data.sidewidth} mm`) : 'hide',
        bheight: `${data.bheight} mm`,
        color: 'slot',
        spray: { '沉金': `沉金，厚度${getValueByKey('cjh')}`, '电金': `电金，厚度${getValueByKey('electrogilding_thickness')}` }[data.spray] || data.spray,
        review_file: 'slot',
        report: data.report || '无需',
        report_type: !data.report ? 'hide' : getValueByKey('report_type'),
        lineweight: hasUnit('lineweight'),
        vias: hasUnit('vias'),
        copper: `${data.copper}oz`,
        insidecopper: data.blayer <= 2 ? 'hide' : `${data.insidecopper}oz`,
        cover: data.cover
      }
      const pcbFormat = {
        board_brand: getValueByKey('board_brand') || data.board_brand,
        deduct_type: 'slot',
        pressing: data.blayer <= 2 ? 'hide' : data.press_name || data.pressing || '无需求',
        insurance_type: getValueByKey('insurance_type')
      }
      const fpcFormat = {
        reinforce: 'slot',
        pi_thickness: hasUnit('pi_thickness'),
      }
      return {
        ...valuesByOptions,
        ...format,
        ...({ PCB: pcbFormat, FPC: fpcFormat }[this.type])
      }
    },
    topData() {
      // 莫问平生意 心期负已多; 别来应尔悔 看去奈吾何
      const { units, bcount = 0, layoutx = 0, layouty = 0, deltime, area, weight, custom_pcb_ban } = this.data
      // PCBA返单
      if (this.isPcbaReturn) {
        return {
          left: { label: 'PCB来源', value: { 1: '在华秋定制', 3: '自己提供' }[custom_pcb_ban] },
          right: [
            (units == 1 ? { label: '生产数量', value: bcount ? `${bcount} Pcs` : '--' } : { label: '生产数量', value: bcount ? `${bcount} Set` : '--', tips: `(${layoutx * layouty * bcount} Pcs)` }),
            { label: '生产周期', value: deltime || '--' },
            { label: '面积', value: area ? `${(area / 10000).toFixed(4)}㎡` : '--' },
            { label: '重量(预估)', value: weight ? `${weight}kg` : '--' },
          ]
        }
      }
      return {
        left: units == 1 ? { label: '生产数量', value: bcount ? `${bcount} Pcs` : '--' } : { label: '生产数量', value: bcount ? `${bcount} Set` : '--', tips: `(${layoutx * layouty * bcount} Pcs)` },
        right: [
          { label: '生产周期', value: deltime || '--' },
          { label: '面积', value: area ? `${(area / 10000).toFixed(4)}㎡` : '--' },
          { label: '重量(预估)', value: weight ? `${weight}kg` : '--' },
        ]
      }
    },
    list() {
      const list = {
        PCB: [
          { width: '165px', children: ['board_type', 'blayer', 'blind', 'board_brand', 'board_tg'] },
          { width: '235px', children: ['units', 'length', 'layout', 'pbnum', 'sidewidth'] },
          { width: '235px', children: ['bheight', 'copper', 'insidecopper', 'lineweight', 'vias'] },
          { width: '300px', children: ['color', 'cover', 'spray', 'overlay_type', 'pressing'] },
          { children: ['via_in_pad', 'beveledge', 'impendance', 'bankong', 'baobian'] },
        ],
        FPC: [
          { width: '165px', children: ['board_type', 'blayer', 'material', 'pi_thickness'] },
          { width: '225px', children: ['units', 'length', 'layout', 'pbnum', 'sidewidth'] },
          { width: '225px', children: ['bheight', 'copper', 'insidecopper', 'lineweight', 'vias'] },
          { width: '280px', children: ['color', 'cover', 'spray', 'overlay_type', 'forming_type'] },
          { width: '193px', children: ['reinforce', 'back_gum_type', 'electromagnetic_membrane', 'conducting_resin', 'impendance'] },
        ]
      }[this.type]
      return list.map(this.format)
    },
    buttonList() {
      const list = {
        PCB: [
          { width: '165px', children: ['user_stamp', 'cross_board'] },
          { width: '235px', children: ['test', 'paper'] },
          { width: '340px', children: ['report', 'report_type'] },
          (this.isPcbaReturn ? { children: ['insurance_type', 'deduct_type'] } : { children: ['review_file', 'deduct_type'] })
        ],
        FPC: [
          { width: '165px', children: ['cross_board'] },
          { width: '225px', children: ['test'] },
          { width: '280px', children: ['report'] },
          { width: '160px', children: ['report_type'] },
          { children: this.isPcbaReturn ? [] : ['review_file'] }
        ]
      }[this.type]
      return list.map(this.format)
    }
  },
  methods: {
    format(item) {
      const { labels, data, fieldsFormat } = this
      item.children = item.children.map(key => ({
        label: labels[key], 
        value: key in fieldsFormat ? fieldsFormat[key] : data[key] || '----',
        slot: fieldsFormat[key] === 'slot' ? key : false
      }))
      return item
    },
    // 补强展示
    reinforceFormat() {
      const data = this.data
      let text = ''
      // 补强类型
      const reinforceType = { steel: '钢片', aluminium: '铝片', fr4: 'FR-4', fingerpi: '手指位PI', otherpi: '其他位置PI' }
      // 是否需要补强
      const needReinforce = Object.keys(reinforceType).some(key => data[`reinforce_${key}`] == 1)

      // 需要补强
      if (needReinforce) {
        text = Object.keys(reinforceType).reduce((prev, cur) => {
          // 勾选的补强类型
          const isCheck = data[`reinforce_${cur}`] == 1
          // 选择的补强厚度
          const val = data[`${cur}_thickness`]
          const valText = val ? `（${val}mm）` : ''
          const curText = isCheck ? `${reinforceType[cur]}${valText}` : ''
          if (curText) prev.push(curText)
          return prev
        }, []).join('、')
      } else {
        text = '不需要'
      }
      return text
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@@/styles/mixin.scss';
.information {
  display: flex;
  padding: 0 15px 12px;

  .information__title {
    flex-shrink: 0;
    position: relative;
    box-sizing: border-box;
    margin-right: 5px;
    width: 40px;
    height: 40px;
    line-height: 38px;
    text-align: center;
    font-weight: 700;
    color: $--form-color-base;
    border-radius: $--border-radius-base;
    border: 1px solid $--form-color-base;
    background-color: $--input-background-color;
    .information__linker {
      @include moduleLinkerMixin(22px, horizontal, dark);
      position: absolute;
      top: -4px;
      right: -4px;
    }
  }
  .information__container {
    flex: 1;
    >div {
      & + div {
        margin-top: 2px;
      }
    }
  }
  .information__btn {
    margin-top: 5px;
    text-align: center;
  }

  .information__color {
    .information__color__text {
      color: var(--color);
    }
  }
  .information__review_file {
    color: $--color-warning;
  }
  .information__deduct_type {
    .tips {
      color: $--color-warning;
    }
    >i {
      color: $--color-primary;
    }
  }
  .information__reinforce {
    .information__reinforce__handler {
      display: block;
      width: 100px;
      @include ellipsis
    }
  }
}
</style>