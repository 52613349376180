<!-- SMT 商品名称 -->
<template>
  <BaseFormItem label="商品名称">
    <BaseInput
      v-model="val"
      width="370px"
      placeholder="给SMT订单起个名字吧"
      @input="handleEmit"
    />
  </BaseFormItem>
</template>

<script>

export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      val: this.value
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    }
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
      this.$emit('change', val)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>