<template>
  <!-- 收货地址 -->
  <BaseFormItem 
    label="收货地址"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <OrderAddress 
      ref="orderAddressRef"
      v-bind="$attrs"
      @change="handleChange"
    />
  </BaseFormItem>
</template>

<script>
// 收货地址
import OrderAddress from '@@/components/address/orderAddress'
export default {
  props: {

  },
  components: {
    OrderAddress
  },
  data() {
    return {
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  methods: {
    init() {
      this.$refs.orderAddressRef && this.$refs.orderAddressRef.init()
    },
    handleChange(...data) {
      this.$emit('change', ...data)
      this.isShowError(false)
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss" scoped>
</style>