<template>
  <div v-loading="loading" class="submit-container">
    <el-form size="small" label-width="105px">
      <!-- 订单信息 -->
      <div class="submit-container__content order-information">
        <p class="submit-container__header">提交订单</p>
        <p class="order-information__desc">请仔细核对订单信息：</p>
        <!-- PCB 订单信息 -->
        <!-- 别后悠悠君莫问，无限事，不言中 -->
        <template v-if="!isShow.onlySmt" >
          <PcbInformation 
            :type="type.includes('FPC') ? 'FPC' : 'PCB'" 
            :data="{...pcbParams, area: pcbPrice.m, weight: pcbPrice.weight, custom_pcb_ban: smtParams.custom_pcb_ban }"
            :isPcbaReturn="isShow.isPcbaReturn"
          />
          <div v-if="!isShow.isPcbaReturn" class="order-information__btn">
            <BackBtn @click="handleChangeOrder({ hash: 1 })" />
          </div>
        </template>
        
        <!-- BOM订单信息 -->
        <template v-if="isShow.isNeedSmt && ([1, 4].includes(smtParams.buy_bom) || isShow.isPcbaReturn)" >
          <BomInformation
            :data="smtParams"
            :type="type"
            :isPcbaReturn="isShow.isPcbaReturn"
          />
          <div v-if="!isShow.isPcbaReturn && smtParams.buy_bom === 4" class="order-information__btn">
            <BackBtn @click="handleGoBom" />
          </div>
        </template>

        <!-- SMT订单信息 -->
        <template v-if="isShow.isNeedSmt" >
          <SmtInformation 
            :data="{...smtParams, normal_dt: smtPrice.normal_dt, weight: smtPrice.weight }" 
            :isPcbaReturn="isShow.isPcbaReturn"
          />
          <!-- 返回修改 -->
          <div class="order-information__btn">
            <BackBtn @click="handleChangeOrder()" />
          </div>
        </template>
      </div>

      <template v-if="!isShow.isPcbaReturn">
        <!-- SMT文件展示 -->
        <div v-if="isShow.isNeedSmt" class="submit-container__content submit-container__file">
          <div class="submit-container__linker"></div>
          <SmtFileView :data="smtParams" />
        </div>

        <!-- 文件上传 -->
        <div v-else class="submit-container__content submit-container__file">
          <div class="submit-container__linker"></div>
          <!-- PCB文件上传 -->
          <BaseFormItem v-if="isShow.pcbfile" label="PCB文件">
            <div class="submit-container__file__upload">
              <!-- PCB文件上传 -->
              <CommonFileUpload 
                ref="pcbfile"
                v-model="pcbParams.pcbfile"
                :fullUrl="true"
              />
              <div class="submit-container__file__upload__tips">
                <p>暂不支持：CAD, Allegro, Gerber274-D, Protel98(及以下版本)的文件</p>
                <ul v-if="pcbParams.board_type === 'FR-4' && pcbParams.blayer <= 2">
                  <li>1、单/双层板提供<span>标准文件</span>，订单审核时工程费将<i>优惠</i>20元</li>
                  <li>2、标准文件的定义：上传的文件中不含有除PCB文件以外的说明文档，且订单备注没有填写生产加工要求</li>
                </ul>
              </div>
            </div>
          </BaseFormItem>
          <!-- SMT文件上传 -->
          <div v-else class="submit-container__file__upload smt-file">
            <!-- PCB文件上传 -->
            <div>
              <p>
                PCB文件
                <BaseTipPopover 
                  v-if="isShow.file_pcb" 
                  :content="`
                    1、单层和双层板提供标准文件，工程费将优惠20 (订单审核时会减去)\n
                    2、标准文件的定义：上传的文件中不含有除PCB文件以外的说明文档，且订单备注没有填写生产加工要求
                  `" 
                />
              </p>
              <CommonFileUpload 
                ref="file_pcb"
                v-if="isShow.file_pcb"
                v-model="smtParams.file_pcb"
                :fullUrl="true"
                @success="data => smtParams.pcb_file_name = data.name"
              />
              <div v-else class="smt-file__ordered">
                <p>订单号：<BaseLink>{{ smtParams.order_info.pcb_order_sn }}</BaseLink></p>
                <p>文件名：<span>{{ smtParams.pcb_file_name }}</span></p>
              </div>
            </div>
            <!-- BOM文件上传 -->
            <div>
              <p>BOM文件</p>
              <CommonFileUpload 
                ref="file_bom"
                v-if="isShow.file_bom"
                v-model="smtParams.file_bom"
                type="bomfile"
                accept=".xls,.xlsx,.csv"
                tips=".xls, .xlsx, .csv格式，小于50M"
                :fullUrl="true"
                @success="data => smtParams.bom_file_name = data.name"
              />
              <div v-else class="smt-file__ordered">
                <p>订单号：<BaseLink>{{ smtParams.order_info.bom_sn }}</BaseLink></p>
                <p>文件名：<span>{{ smtParams.bom_file_name }}</span></p>
              </div>
            </div>
            <!-- SMT文件(坐标文件)文件上传 -->
            <div>
              <p>SMT文件(坐标文件)</p>
              <CommonFileUpload 
                ref="file_patch"
                v-model="smtParams.file_patch"
                type="smtfile"
                tips="zip, rar格式，小于50MB"
                :fullUrl="true"
                @success="data => smtParams.smt_file_name = data.name"
              />
            </div>
          </div>
        </div>
      </template>

      <!-- 个性化信息 -->
      <div class="submit-container__content">
        <div class="submit-container__linker"></div>
        <div class="submit-container__row">
          <!-- PCB个性化信息 -->
          <div v-if="!isShow.onlySmt">
            <p class="submit-container__title">PCB个性化信息</p>
            <!-- 工程EQ处理方式 -->
            <eq_notice ref="eq_notice" v-model="pcbParams.eq_notice" />
            <!-- 工程师信息 -->
            <BaseFormItem v-if="isShow.engineer_id" label="工程师信息" class="submit-container__engineer">
              <Engineer 
                ref="pcbEngineerRef" 
                v-model="pcbParams.engineer_id" 
                @addSuccess="$refs.smtEngineerRef && $refs.smtEngineerRef.getList()"
              />
            </BaseFormItem>
            <!-- 包装要求 -->
            <hq_pack ref="hq_pack" v-if="isShow.hq_pack" v-model="pcbParams.hq_pack" />
            <!-- 内包装标签备注 -->
            <label_remark v-if="isShow.label_remark" v-model="pcbParams.label_remark" />
            <!-- 华秋标识和制造周期 -->
            <has_period 
              ref="has_period" 
              v-model="pcbParams.has_period" 
              :period_format="pcbParams.period_format" 
              :type="type"
            />
            <!-- 周期格式 -->
            <period_format 
              v-if="isShow.period_format" 
              ref="period_format" 
              v-model="pcbParams.period_format" 
            />
            <!-- 审核通知 -->
            <sh v-model="pcbParams.sh" />
            <!-- 发货通知 -->
            <fh v-if="isShow.fh" v-model="pcbParams.fh" />
            <!-- 送货单 -->
            <fhp v-if="isShow.fhp" :fhp.sync="pcbParams.fhp"  :fhd_title.sync="pcbParams.fhd_title" />
            <!-- 订单备注 -->
            <note v-if="isShow.note" v-model="pcbParams.note" />
          </div>
          <!-- SMT个性化信息 -->
          <div v-if="isShow.isNeedSmt">
            <p class="submit-container__title">SMT个性化信息</p>
            <template v-if="smtParams.order_info">
              <!-- 工程师信息 -->
              <BaseFormItem label="工程师信息" class="submit-container__engineer">
                <Engineer 
                  ref="smtEngineerRef" 
                  radioKey="name"
                  v-model="smtParams.order_info.add_user_id" 
                  @change="handleSmtEngineerChange" 
                  @addSuccess="$refs.pcbEngineerRef && $refs.pcbEngineerRef.getList()"
                />
              </BaseFormItem>
              <template v-if="!isShow.isPcbaReturn">
                <!-- 商品名称 -->
                <goods_name v-model="smtParams.order_info.goods_name" />
                <!-- 订单备注 -->
                <remark v-model="smtParams.order_info.remark" />
              </template>
            </template>
          </div>
        </div>
      </div>

      <!-- 配送信息 -->
      <div class="submit-container__content">
        <div class="submit-container__linker"></div>
        <p class="submit-container__title">配送信息</p>

        <!-- 配送方式 -->
        <gain_order_type 
          v-if="isShow.gain_order_type"
          ref="gain_order_type" 
          v-model="smtParams.gain_order_type" 
          @change="handleGainOrderTypeChange"
        />
        <!-- 提货人 -->
        <pickup 
          v-if="isShow.pickup"
          ref="pickup"
          :pickup_uname.sync="smtParams.order_info.pickup_uname"
          :pickup_phone.sync="smtParams.order_info.pickup_phone" 
        />
        <template v-else>
          <!-- 收货地址 -->
          <Address ref="addressRef" @change="handleAddressChange" />
          <!-- 快递公司 -->
          <Express
            ref="expressRef"
            v-model="pcbParams.express" 
            :mountRef="(ref) => (expressListRef = ref)" 
            :type="isShow.isNeedSmt ? 'SMT' : 'PCB'"
            :params="expressParams" 
            @change="handleExpressChange" 
          />
        </template>
        
        <!-- PCB 下单人 -->
        <orderman_id 
          v-if="isShow.orderman_id"
          ref="ordermanRef"
          v-model="pcbParams.orderman_id" 
          @change="handleOrderManChange" 
        />
        <!-- 发票信息 -->
        <p class="submit-container__title invoice">
          发票信息
          <span>
            每张订单只能申请一次发票，请仔细核对
          </span>
        </p>
        <!-- 一起开票 -->
        <BaseFormItem v-if="isShow.invoice_together" label="一起开票">
          <BaseRadioGroup ref="invoice_together" v-model="smtParams.order_info.invoice_together">
            <el-radio-button :label="1">一起开票</el-radio-button>
            <el-radio-button :label="2">分开开票</el-radio-button>
          </BaseRadioGroup>
        </BaseFormItem>
        <!-- 发票类型 -->
        <BaseFormItem label="开票类型" >
          <InvoiceType
            ref="invoice"
            :value="pcbParams.invoice" 
            :amount="priceTotal.tax_amount"
            :hideOptios="isShow.isNeedSmt ? ['不需要'] : []"
            @change="handleInvoiceTypeChange"
          />
        </BaseFormItem>
        <!-- 发票抬头 -->
        <Invoice 
          v-if="isShow.bill_id"
          ref="bill_id"
          v-model="pcbParams.bill_id" 
          :type="{ '普票': 2, '增票全电': 1 }[pcbParams.invoice] || 2" 
          @change="handleInvoiceChange"
        />
      </div>
    </el-form>
    <!-- 价格计算 -->
    <div class="submit-container__price">
      <!-- SMT 总费用明细 -->
      <SmtPriceTotalView 
        v-if="isShow.isNeedSmt"
        :type="type" 
        :data="priceTotal" 
        :showPcb="isShow.isNeedSmt && smtParams.custom_pcb_ban == 1"
        :showBom="isShow.isNeedSmt && [1, 4].includes(smtParams.buy_bom) && [0, 1, 2].includes(smtParams.order_info.model_selection_type)"
        :isFpcNeedAudit="isFpcNeedAudit"
      />
      <!-- PCB 总费用明细 -->
      <PcbPriceTotalView 
        v-else
        :type="type" 
        :data="priceTotal" 
        :isFpcNeedAudit="isFpcNeedAudit"
      />
      <!-- 快板免费加急活动提示 -->
      <p v-if="isShow.isNeedSmt && deliveryTips" class="submit-container__price__deliveryTips">
        {{ deliveryTips }}
      </p>
      <div class="submit-container__price__total">
        预估总价：
        <span v-if="isFpcNeedAudit">
          ----
        </span>
        <template v-else>
          <span>￥</span>
          <span>
            <QuoteAnimateNumber :num="priceTotal.total_amount" />
          </span>
        </template>
      </div>
      <p class="submit-container__price__tips">
        <template v-if="isFpcNeedAudit && type === 'FPC'">
          软硬结合板具体价格<span>以审核后为准</span>
        </template>
        <template v-else>
          仅供参考，最终价格以审核后为准
        </template>
      </p>
      <p v-if="isShow.onlyPcb" class="submit-container__price__deltime">
        今日18:00前付款，预计 <span>{{ priceTotal.delivery_date }} 22:00</span> 前发货
      </p>
      <!-- SMT协议 -->
      <SmtProtocol 
        ref="agreen_protocol"
        v-if="isShow.isNeedSmt" 
        v-model="smtParams.agreen_protocol" 
        :pcbaPdfName="smtParams.address ? smtParams.address.consignee : ''" 
      />
      <div>
        <span v-if="isShow.isPcbaReturn" class="submit-container__price__pcbaReturnTips">
          *请确认返单工艺信息无误后下单。若当前选择的工艺不满足您的需求，请下新单！
        </span>
        <BaseButton 
          round
          size="" 
          class="submit-container__price__btn" 
          style="width: 310px" 
          :loading="submitLoading" 
          @click="handleSubmit"
        >确认提交</BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
// 返回修改按钮
import BackBtn from './components/backBtn'
// PCB订单信息
import PcbInformation from './information/pcb'
// SMT订单信息
import SmtInformation from './information/smt'
// BOM订单信息
import BomInformation from './information/bom'
// 收货地址
import Address from './fields/address'
// 快递公司
import { SMT_EXPRESS_LINKER } from '@@/components/expressSelect/list'
import Express from './fields/express'
// 发票类型
import InvoiceType from '@@/components/invoice/invoiceType'
// 发票列表
import Invoice from './fields/invoice'
// 工程师列表
import Engineer from '@@/components/engineer/index'
// 文件上传
import CommonFileUpload from '@@/components/common/commonFileUpload'
// 数字跳动效果
import QuoteAnimateNumber from '@@/components/common/quoteAnimateNumber'
// SMT合同、协议
import SmtProtocol from './protocol/index'
// PCB总费用明细
import PcbPriceTotalView from './priceTotalView/pcb'
import SmtPriceTotalView from './priceTotalView/smt'
// SMT文件展示
import SmtFileView from './components/smtFileView'

import { isPcbSite, isSmtSite } from '@@/utils/is'
import { FR4_DEFAULT_FORM } from '@@/utils/defaultForm'
import { sensorsTrack } from '@@/utils/sensors'
import { targetSelfWindow } from '@@/utils/triggerParent'
import { ORDER_TYPE_ENUMS, PCBA_RETURN_PARAMS_SESSION_KEY } from '@@/utils/constant'
import { getBomUrl } from '@@/utils/bom'
import { debounce } from '@@/utils/utils'
import { scrollTop } from '@@/utils/pageScroll'
import { isFpcNeedAudit } from '@@/utils/fpcParamsFormat'
import { isShowSubmitParams, formatSubmitPcbParams, validateSubmit } from '@@/utils/submitParamsFormat'
import { formatSmtParams, formatDeliveryTips } from '@@/utils/smtParamsFormat'
import { formatPcbaReturnParams } from '@@/utils/pcbaReturnParamsFormat'
import { getPcbQuote, getPcbPrice, submitPcbQuote } from '@@/api/pcb'
import { getSmtQuote, getSmtPrice, submitSmtQuote } from '@@/api/smt'
import { getOrderTemp, getPcbaPrice, submitPcbaOrder, savePcbaReturnOrder } from '@@/api/pcba'
import { getSortBySsoUid } from '@@/api/center'

// 自动注册 pcbFields 文件夹下的订单字段组件
const requirePcbFields = require.context('./pcbFields', true, /\.vue$/)
let pcbFieldsComponents = {}
requirePcbFields.keys().forEach(fileName => {
  let component = requirePcbFields(fileName)
  pcbFieldsComponents[fileName.replace(/^\.\/(.*)\.\w+$/, '$1')] = component.default
})
// 自动注册 pcbFields 文件夹下的订单字段组件
const requireSmtFields = require.context('./smtFields', true, /\.vue$/)
let smtFieldsComponents = {}
requireSmtFields.keys().forEach(fileName => {
  let component = requireSmtFields(fileName)
  smtFieldsComponents[fileName.replace(/^\.\/(.*)\.\w+$/, '$1')] = component.default
})

export default {
  components: {
    ...pcbFieldsComponents,
    ...smtFieldsComponents,
    BackBtn,
    PcbInformation,
    SmtInformation,
    BomInformation,
    Address,
    Express,
    InvoiceType,
    Invoice,
    Engineer,
    CommonFileUpload,
    QuoteAnimateNumber,
    SmtProtocol,
    PcbPriceTotalView,
    SmtPriceTotalView,
    SmtFileView
  },
  data() {
    return {
      loading: false,
      submitLoading: false,
      type: '',
      pcbParams: {},
      smtParams: {},
      pcbOrderParams: {}, // SMT关联已下单的PCB订单参数
      pcbPrice: {}, // PCB价格
      smtPrice: {}, // SMT价格
      bomPrice: {}, // BOM价格
      priceTotal: {},
      expressListRef: null, // 快递列表组件
      debounceGetPirce: debounce(this.getPrice, 300), // 计价接口防抖
    }
  },
  computed: {
    isShow() {
      const { type, pcbParams, smtParams } = this
      return isShowSubmitParams(type, pcbParams, smtParams)
    },
    // FPC 板子层数为 8 10 12 价格以审核为准
    isFpcNeedAudit() {
      const { type, pcbParams } = this
      return isFpcNeedAudit(type, pcbParams)
    },
    // 获取快递列表参数
    expressParams() {
      const { isShow, pcbParams, smtParams } = this
      // PCBA订单
      if (isShow.isNeedSmt) {
        const { number, province_id, custom_pcb_ban, pcb_ban_width, pcb_ban_height, pcb_ban_x, pcb_ban_y, packing_type } = smtParams
        const { length, width, layoutx, layouty, blength, bwidth } = pcbParams
        const pcbSizeParams = {
          // 在华秋定制
          1: { length, width, layoutx, layouty, pcb_ban_height: 0, pcb_ban_width: 0 },
          // 已在华秋下单
          2: { length: 1, width: 1, layoutx, layouty, pcb_ban_height: 0, pcb_ban_width: 0 },
          // 自己提供
          3: { length: 0, width: 0, layoutx: pcb_ban_x, layouty: pcb_ban_y, pcb_ban_height, pcb_ban_width },
        }[custom_pcb_ban] || {}
        return { ...pcbSizeParams, number, province_id, packing_type }
      }
      // PCB订单
      return { ...pcbParams }
    },
    // 快板免费加急活动提示
    deliveryTips() {
      return formatDeliveryTips(this.priceTotal.activity || {})
    }
  },
  watch: {
    pcbParams: {
      deep: true,
      handler: function (val) {
        this.debounceGetPirce()
      }
    },
    smtParams: {
      deep: true,
      handler: function (val) {
        this.debounceGetPirce()
      }
    }
  },
  created() {
    const { type, id, pcbaReId } = this.$route.query
    this.type = ORDER_TYPE_ENUMS[type]
    // PCBA返单
    if (pcbaReId) {
      this.getPcbaReturnParams(pcbaReId)
    } else {
      this.getParams(id)
    }
  },
  mounted() {
    // 神策数据埋点
    sensorsTrack('PCB1_submit_order_view', { entrance: '新版提交订单页页面浏览' })
  },
  methods: {
    // 配送方式
    handleGainOrderTypeChange(val) {
      if (val === 0) {
        this.getAddressList()
      }
    },
    // 收货地址
    handleAddressChange(item) {
      const { id, telMobile, combinAddr, addr, province, city, district } = item
      this.pcbParams.aid = id
      this.pcbParams.address = `${combinAddr}${addr}`
      this.pcbParams.recevtel = telMobile
      this.pcbParams.province = province
      this.pcbParams.city = city
      this.pcbParams.district = district

      this.smtParams.address = item
      this.smtParams.province_id = province
      // 获取快递选项
      this.$nextTick(() => {
        this.expressListRef.getList()
      })
    },
    // 快递
    handleExpressChange(item = {}) {
      const { shipping_id = '', shipping_pay_type = '' } = item
      this.pcbParams.express = shipping_id
      if (this.isShow.isNeedSmt) {
        this.smtParams.express = shipping_id ? shipping_id.split(SMT_EXPRESS_LINKER)[0] : ''
        this.smtParams.express_type = shipping_pay_type
      }
    },
    // 下单人信息
    handleOrderManChange(item) {
      const { orderMan, orderTel } = item || {}
      this.pcbParams.orderman = orderMan
      this.pcbParams.ordertel = orderTel
    },
    // 发票类型改变
    handleInvoiceTypeChange(item) {
      this.$set(this.pcbParams, 'invoice', item)
      if (this.isShow.isNeedSmt && this.smtParams.order_info) {
        this.smtParams.order_info.vat_type = { '不需要': 3, '普票': 5, '增票全电': 4 }[item]
      }
      if (item === '不需要') {
        this.pcbParams.bill_id = ''
        this.smtParams.invoice = {}
      }
    },
    // 发票抬头
    handleInvoiceChange(item) {
      if (item) {
        let invoiceType = { 1: '增票全电', 2: '普票' }[item.invoiceType]
        this.pcbParams.invoice = invoiceType
      }
      this.smtParams.invoice = this.isShow.isNeedSmt ? item || {} : {}
    },
    // SMT工程师信息
    handleSmtEngineerChange(item) {
      const { name, tel, email } = item || {}
      this.smtParams.order_info.add_user = name
      this.smtParams.order_info.add_user_tel = tel
      this.smtParams.order_info.add_user_email = email
    },

    // 获取发票邮箱
    getInvoiceEmail() {
      getSortBySsoUid()
        .then(res => {
          if (!res) return
          const item = (res.body || [])[0] || {}
          this.smtParams.order_info.deliver_email = item.email || ''
        })
    },
    // 获取收货地址列表
    getAddressList() {
      this.$nextTick(() => {
        this.$refs.addressRef && this.$refs.addressRef.init()
      })
    },
    // PCB配置的参数默认值
    async getPcbDefaultValueConfig() {
      try {
        if (this.type !== 'PCB') return {}
        // 提交页配置默认值的字段
        const fields = ['eq_notice', 'hq_pack', 'has_period', 'period_format', 'sh', 'fh']
        await this.$store.dispatch('defaultValueConfig/getPcbDefaultValueConfig')
        const defaultValueConfig = this.$store.state.defaultValueConfig.PCB || {}
        return fields.reduce((data, key) => {
          const val = defaultValueConfig[key]
          if (val) {
            if (['none', 'default'].includes(val)) {
              data[key] = { 'none': '', 'default': FR4_DEFAULT_FORM[key] }[val]
            } else {
              data[key] = Number(val)
            }
          }
          return data
        }, {})
      } catch (error) {
        return {}
      }
    },
    // 获取订单信息
    async getParams(id) {
      try {
        this.loading = true
        this.isShow.isNeedSmt ? await this.getSmtParams(id) : await this.getPcbParams(id)
        this.$nextTick(() => {
          // 初始化下单人
          this.$refs.ordermanRef && this.$refs.ordermanRef.init()
          this.getAddressList()
        })
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    // 获取PCB订单信息
    async getPcbParams(quoteid) {
      // 配置的参数默认值
      const defaultValue = await this.getPcbDefaultValueConfig()
      return await getPcbQuote({ quoteid })
        .then(res => {
          if (!res) return false
          const { retCode, retMsg, result } = res
          if (retCode === 0) {
            const { quote, extend, notify } = result
            delete quote.pressing_data
            this.pcbParams = Object.assign({}, {
              ...extend,
              ...notify,
              ...quote,
              ...defaultValue,
              quoteid
            })
          } else {
            this.$message.error(retMsg)
          }
        })
    },
    // 获取SMT订单信息
    async getSmtParams(smtTmpId) {

      return await getOrderTemp({ one_stop_temp_id: smtTmpId })
        .then(res => {
          if (!res) return false
          const { retCode, result, retMsg } = res
          if (retCode === 0) {
            const { pcb_params, smt_params, order_info = {}, address = {}, invoice = {} } = result
            this.pcbParams = pcb_params || {}
            // 保存关联已下单的PCB订单参数
            if (smt_params.custom_pcb_ban == 2) {
              this.pcbOrderParams = pcb_params
              if (order_info.vat_type || order_info.vat_type === 0) {
                this.pcbParams.invoice = { 3: '不需要', 5: '普票', 1: '增票', 4: '增票全电' }[order_info.vat_type]
              }
            }
            // SMT的快递id不是唯一的，需要拼接支付类型
            if (smt_params.express && smt_params.express_type) {
              this.pcbParams.express = `${smt_params.express}${SMT_EXPRESS_LINKER}${smt_params.express_type}`
            }
            this.smtParams = {
              ...smt_params, 
              order_info: { 
                ...order_info, 
                // PCBA订单id
                one_stop_temp_id: smtTmpId,
                // 仅下SMT单时，一起开票 默认为 分开开票
                invoice_together: this.isShow.onlySmt ? 2 : order_info.invoice_together || 2 
              },
              address, 
              invoice, 
              agreen_protocol: false 
            }
            // BOM价格相关
            if (smt_params.buy_bom == 1 || (smt_params.buy_bom == 4 && order_info.bom_self_id)) {
              this.bomPrice = Object.assign(this.bomPrice, {
                buy_bom: smt_params.buy_bom,
                bomId: order_info.bom_self_id,
                modelSelectionType: order_info.model_selection_type
              })
            }
            this.getInvoiceEmail()
          } else {
            this.$message.error(retMsg)
          }
        })
    },
    // 获取PCBA返单信息
    getPcbaReturnParams(pcbaReId) {
      try {
        this.loading = true
        const params = JSON.parse(sessionStorage.getItem(`${PCBA_RETURN_PARAMS_SESSION_KEY}_${pcbaReId}`))
        if (params) {
          const { pcb_params, smt_params, order_info = {}, address = {}, invoice = {} } = params
          this.pcbParams = { ...(pcb_params || {}) }
          // SMT的快递id不是唯一的，需要拼接支付类型
          if (smt_params.express && smt_params.express_type) {
            this.pcbParams.express = `${smt_params.express}${SMT_EXPRESS_LINKER}${smt_params.express_type}`
          }
          this.smtParams = {
            ...smt_params, 
            order_info: { 
              ...order_info, 
              // 仅下SMT单时，一起开票 默认为 分开开票
              invoice_together: this.isShow.onlySmt ? 2 : order_info.invoice_together || 2 
            },
            address, 
            invoice, 
            agreen_protocol: false 
          }

          this.$nextTick(() => {
            // 初始化下单人
            this.$refs.ordermanRef && this.$refs.ordermanRef.init()
            this.getAddressList()
          })
        } else {
          // 当没有获取到PCBA返单信息时，返回PCBA返单计价页
          targetSelfWindow({
            path: '/orders/pcba/return',
            query: { demandSn: pcbaReId, source: 'submit' }
          })
        }
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },

    // 计价
    async getPrice() {
      const res = this.isShow.isNeedSmt ? await this.getSmtPrice() : await this.getPcbPrice()

      const { detail = {} } = res
      const { pcb = {}, smt = {} } = detail

      this.pcbPrice = pcb
      this.smtPrice = smt
      this.priceTotal = res
    },
    // PCB 计价
    async getPcbPrice() {
      const params = { ...this.pcbParams }
      // 获取优惠券列表
      if (this.isShow.onlyPcb) {
        params.want_bonus_activity = 1
      }
      return await getPcbPrice(params)
        .then(res => {
          if (!res) return {}
          const { retCode, result } = res
          return retCode === 0 ? result : {}
        })
    },
    // SMT + PCB 计价
    async getSmtPrice() {
      const { type, pcbParams, pcbOrderParams, smtParams, bomPrice, isShow } = this
      let params
      // PCBA返单计价参数
      if (isShow.isPcbaReturn) {
        params = formatPcbaReturnParams(smtParams, pcbParams)
      } else {
        // PCBA订单计价参数
        params = { ...formatSmtParams(smtParams) }
        // 不是仅下SMT时，需要PCB订单参数
        if (type !== 'SMT') {
          params.pcb_params = formatSubmitPcbParams(type, pcbParams)
        } else {
          params.pcb_params = pcbOrderParams
        }
      }
      
      return await getPcbaPrice(params)
        .then(res => {
          if (!res) return {}
          const { retCode, result } = res
          return retCode === 0 
            ? Object.assign(result, { bomPrice: bomPrice || {} }) 
            : {}
        })
    },

    // BOM 返回修改
    handleGoBom() {
      const { bom_self_id, one_stop_temp_id } = this.smtParams.order_info || {}
      location.href = getBomUrl({ bomId: bom_self_id, id: one_stop_temp_id, type: this.type, edit: 1 })
    },
    // 返回修改
    handleChangeOrder(otherQuery = {}) {
      const query = this.$route.query
      // PCB站点
      if (isPcbSite) {
        const path = { PCB: '/quote_new', FPC: '/quote_new/fpc' }[this.type]
        this.$router.push({ path, query })
      } else if (isSmtSite) {
        // SMT站点
        this.$router.push({ path: '/quote', query: { ...query, ...otherQuery, source: 'submit' } })
      } else {
        // 用户中心站点
        // PCBA返单
        if (this.isShow.isPcbaReturn) {
          targetSelfWindow({
            path: '/orders/pcba/return',
            query: {
              demandSn: this.$route.query.pcbaReId, 
              source: 'submit',
              type: this.smtParams.order_info.return_order_type || 0
            }
          })
        } else {
          // 跳转到对应的计价页
          let path = this.$route.path.replace('submit', 'quote')
          const query = this.$route.query
          targetSelfWindow({ path, query: { ...query, ...otherQuery, source: 'submit' } })
        }
      }
    },

    // 滚动到对应的组件位置
    scrollIntoView(key) {
      if (this.$refs[key]) {
        // 滚动到元素上面偏移50px的位置
        const top = this.$refs[key].$el.getBoundingClientRect().top
        scrollTop(top, -50)
      }
    },
    // 提交校验
    validate() {
      const { type, pcbParams, smtParams } = this
      const valid = validateSubmit(type, pcbParams, smtParams)
      if (valid.length > 0) {
        const keyMap = {
          orderman_id: 'ordermanRef',
          aid: 'addressRef',
          express: 'expressRef',
          engineer_id: 'pcbEngineerRef',
          add_user: 'smtEngineerRef'
        }
        valid.forEach((item, index) => {
          const ref = this.$refs[keyMap[item.key] || item.key]
          if (ref && ref.isShowError) {
            // 显示错误提示样式
            ref.isShowError(true, index === 0)
          }
        })
        const fristItem = valid[0]
        this.$message.closeAll()
        this.$message.error(fristItem.tips)
        this.scrollIntoView(keyMap[fristItem.key] || fristItem.key)
        return false
      }
      return true
    },
    // 提交订单
    async handleSubmit() {
      try {
        const valid = this.validate()
        if (!valid) return

        this.submitLoading = true
        const { type, isShow } = this

        // PCBA返单
        if (isShow.isPcbaReturn) {
          const data = await this.submitPcbaReturnOrder()
          if (data) {
            const id = data.demandPcbaId
            sessionStorage.setItem(`orders_${id}`, JSON.stringify({ type, data, isPcbaReturn: 1 }))
            targetSelfWindow({ path: '/orders/pcba/success', query: { id } })
          }
        } else {
          const data = isShow.isNeedSmt ? await this.submitPcbaOrder() : await this.submitPcbQuote()
          if (data) {
            const id = isShow.isNeedSmt ? data.demandPcbaId : data.id
            sessionStorage.setItem(`orders_${id}`, JSON.stringify({ type, data }))
            // PCB站点
            if (isPcbSite) {
              this.$router.push({ 
                path: '/quote_new/success',
                query: { id }
              })
            } else if (isSmtSite) {
              // SMT站点
              this.$router.push({ path: '/quote/success', query: { id } })
            }  else {
              // 用户中心站点
              // 跳转到对应的提交页成功页（共用一个提交页成功页，路由不同）
              const path = this.$route.path.replace('submit', 'success')
              targetSelfWindow({ path, query: { id } })
            }
          }
        }
        
        this.submitLoading = false
        return false
      } catch (error) {
        this.submitLoading = false
      }
    },
    // 提交PCB订单
    async submitPcbQuote() {
      this.pcbSensorsTrack(this.pcbParams)
      return await submitPcbQuote(this.pcbParams)
        .then(res => {
          if (!res) return false
          const { retCode, retMsg, result, errInfo } = res
          if (retCode === 0) {
            return result
          } else if (retCode === 101075 && errInfo) {
            const { tips, suggest } = errInfo
            this.$confirm(tips, '温馨提示', {
              confirmButtonText: '同意变更',
              cancelButtonText: '不同意',
              lockScroll: false,
              center: true,
              dangerouslyUseHTMLString: true,
              closeOnClickModal: false,
              closeOnPressEscape: true
            }).then(() => {
              this.pcbParams = Object.assign(this.pcbParams, suggest || {})
              this.handleSubmit()
              return false
            }).catch(() => false)
          } else {
            this.$message.error(retMsg || '未知错误，请稍后重试')
            return false
          }
        })
    },
    // 提交SMT订单
    async submitSmtQuote() {
      try {
        const { type, pcbParams, pcbOrderParams, smtParams } = this
        const params = formatSmtParams(smtParams)
        // 不是仅下SMT时，需要先暂存PCB订单
        if (type !== 'SMT') {
          params.pcb_params = formatSubmitPcbParams(type, pcbParams)
        } else {
          params.pcb_params = pcbOrderParams
        }
        return await submitSmtQuote(params)
          .then(res => {
            if (!res) return false
            const { suc, code, body = {}, msg } = res
            if (suc) {
              return body
            } else if (code == 510002) {
              // PCB交期动态变更
              this.$msgbox({
                title: '温馨提示',
                message: `PCB订单由于动态交期变更，PCB交期变更为${body.deltime}`,
                confirmButtonText: '好的',
                customClass: 'submit-container__confirmDialog',
                lockScroll: false,
                center: true,
                dangerouslyUseHTMLString: true,
                closeOnClickModal: false,
                closeOnPressEscape: true,
                showCancelButton: false,
                showClose: false,
                beforeClose: async (action, instance, done) => {
                  if (action === 'confirm') {
                    instance.confirmButtonLoading = true
                    instance.confirmButtonText = '好的'
                    this.pcbParams = Object.assign(this.pcbParams, body)
                    await this.handleSubmit()
                    done()
                  } else {
                    done()
                  }
                }
              })

              return false
            }
            this.$message.error(msg || '未知错误，请稍后重试')
            return false
          })
      } catch (error) {
        console.log('catch', error)
        return false
      }
    },
    // 提交PCBA订单
    async submitPcbaOrder() {
      try {
        const { type, pcbParams, pcbOrderParams, smtParams } = this
        const params = formatSmtParams(smtParams)
        // 不是仅下SMT时，需要先暂存PCB订单
        if (type !== 'SMT') {
          params.pcb_params = formatSubmitPcbParams(type, pcbParams)
        } else {
          params.pcb_params = pcbOrderParams
        }
        return await submitPcbaOrder(params)
          .then(res => {
            if (!res) return false
            const { retCode, result = {}, retMsg, errInfo } = res
            if (retCode === 0) {
              return result
            } else if (retCode == 510003) {
              const data = JSON.parse(errInfo || "{}")
              // PCB交期动态变更
              this.$msgbox({
                title: '温馨提示',
                message: `PCB订单由于动态交期变更，PCB交期变更为${data.deltime}`,
                confirmButtonText: '好的',
                customClass: 'submit-container__confirmDialog',
                lockScroll: false,
                center: true,
                dangerouslyUseHTMLString: true,
                closeOnClickModal: false,
                closeOnPressEscape: true,
                showCancelButton: false,
                showClose: false,
                beforeClose: async (action, instance, done) => {
                  if (action === 'confirm') {
                    instance.confirmButtonLoading = true
                    instance.confirmButtonText = '好的'
                    this.pcbParams = Object.assign(this.pcbParams, data)
                    await this.handleSubmit()
                    done()
                  } else {
                    done()
                  }
                }
              })
              return false
            }
            else if (retCode == 510004) {
              // BOM折扣失效提示
              this.$msgbox({
                title: '温馨提示',
                message: retMsg,
                confirmButtonText: '好的，继续提交',
                cancelButtonText: '取消提交',
                customClass: 'submit-container__confirmDialog',
                lockScroll: false,
                center: true,
                dangerouslyUseHTMLString: true,
                closeOnClickModal: false,
                closeOnPressEscape: true,
                showCancelButton: true,
                showClose: false,
                beforeClose: async (action, instance, done) => {
                  if (action === 'confirm') {
                    instance.confirmButtonLoading = true
                    instance.confirmButtonText = '好的，继续提交'
                    await this.handleSubmit()
                    done()
                  } else {
                    this.getPrice()
                    done()
                  }
                }
              })
              return false
            }
            this.$message.error(retMsg || '未知错误，请稍后重试')
            return false
          })
      } catch (error) {
        console.log('catch', error)
        return false
      }
    },
    // 提交PCBA返单
    async submitPcbaReturnOrder() {
      try {
        const { pcbParams, smtParams } = this
        const params = formatPcbaReturnParams(smtParams, pcbParams)
        return await savePcbaReturnOrder(params)
          .then(res => {
            if (!res) return false
            const { retCode, result = {}, retMsg, errInfo } = res
            if (retCode === 0) {
              return result
            } else if (retCode == 510003) {
              const data = JSON.parse(errInfo || "{}")
              // PCB交期动态变更
              this.$msgbox({
                title: '温馨提示',
                message: `PCB订单由于动态交期变更，PCB交期变更为${data.deltime}`,
                confirmButtonText: '好的',
                customClass: 'submit-container__confirmDialog',
                lockScroll: false,
                center: true,
                dangerouslyUseHTMLString: true,
                closeOnClickModal: false,
                closeOnPressEscape: true,
                showCancelButton: false,
                showClose: false,
                beforeClose: async (action, instance, done) => {
                  if (action === 'confirm') {
                    instance.confirmButtonLoading = true
                    instance.confirmButtonText = '好的'
                    this.pcbParams = Object.assign(this.pcbParams, data)
                    await this.handleSubmit()
                    done()
                  } else {
                    done()
                  }
                }
              })
              return false
            } else {
              this.$message.error(retMsg || '未知错误，请稍后重试')
              return false
            }
          })
      } catch (error) {
        console.log('catch', error)
        return false
      }
    },

    // PCB/FPC提交订单神策埋点
    pcbSensorsTrack(params) {
      const type = this.type
      const data = {
        entrance: { PCB: 'PCB-提交订单', FPC: 'FPC-提交订单' }[type],
        ...params
      }
      // 神策数据埋点
      sensorsTrack('addtocart', data)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@@/styles/mixin.scss';
.submit-container {
  $contentBorderRadius: 5px;
  .submit-container__content {
    position: relative;
    min-height: 100px;
    border: $--border-base;
    border-radius: $contentBorderRadius;
    background-color: #fff;
    &:hover {
      border-color: #AAAAAA;
      box-shadow: 0px 0px 5px 0px rgba(127,127,127,0.35); 
    }
    & + .submit-container__content {
      margin-top: 6px;
    }
  }
  .submit-container__header {
    padding-left: 15px;
    height: 40px;
    line-height: 40px;
    font-size: 15px;
    color: $--color-text-primary;
    font-weight: 700;
    border-bottom: 1px solid #EFEFEF;
    border-top-left-radius: $contentBorderRadius;
    border-top-right-radius: $contentBorderRadius;
    background-color: #FBFBFB;
    >span {
      font-weight: 400;
    }
  }
  .submit-container__title {
    display: flex;
    padding: 4px 15px 8px;
    height: 28px;
    line-height: 28px;
    font-size: 13px;
    font-weight: bold;
    .title-warning {
      margin-left: 20px;
      padding: 0 8px;
      font-size: 12px;
      font-weight: normal;
      color: #E6A23C;
      height: 28px;
      background: #fdf6ec;
      border-radius: 3px;
      i {
        font-size: 15px;
        margin-right: 6px;
      }
    }
    &.invoice {
      align-items: center;
      position: relative;
      padding-top: 16px;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        display: block;
        width: calc(100% - 30px);
        border-top: 1px dashed $--border-color-base;
      }
      >span {
        margin-left: 38px;
        padding: 4px 15px 4px 8px;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: $--color-warning;
        border-radius: $--border-radius-base;
        background-color: #FDF6EC;
      }
    }
  }
  .submit-container__linker {
    @include moduleLinkerMixin(20px);
    position: absolute;
    top: -13px;
    left: 6px;
  }

  .submit-container__row {
    display: flex;
    >div {
      width: 50%;
      & + div {
        position: relative;
        &::before {
          position: absolute;
          content: '';
          top: 20px;
          bottom: 20px;
          left: -20px;
          width: 1px;
          background: linear-gradient(180deg,#fff, #e3e3e3 50%, #fff);
        }
      }
    }
    .submit-container__engineer {
      ::v-deep {
        .base-form-item__content {
          width: 440px;
        }
      }
    }
  }

  .submit-container__express {
    width: 360px;
  }

  ::v-deep {
    .el-loading-spinner .path {
      stroke: $--form-color-base;
    }
  }
}

/* 订单参数 */
.order-information {
  .order-information__desc {
    padding: 7px 15px 9px;
    font-size: 13px;
    color: $--color-text-disabled;
  }
  .order-information__btn {
    margin-bottom: 12px;
    text-align: center;
  }
}

.submit-container__file {
  padding-top: 20px;
  .submit-container__file__upload {
    display: flex;
    align-items: center;
    .submit-container__file__upload__tips {
      margin-left: 25px;
      line-height: 1.5;
      font-size: 12px;
      color: $--color-text-secondary;
      >ul {
        margin-top: 10px;
        color: #707070;
        span {
          color: $--color-warning;
        }
        i {
          color: #D9001B;
        }
      }
    }
    &.smt-file {
      padding: 0 23px 19px;
      >div {
        margin-right: 20px;
        >p {
          margin-bottom: 8px;
          font-weight: 700;
          &::before {
            content: '*';
            margin-right: 2px;
            color: $--color-danger;
          }
        }
      }
      .smt-file__ordered {
        width: 360px;
        height: 98px;
        padding: 11px 17px;
        background: #f5f7fa;
        border-radius: 5px;
        box-sizing: border-box;
        >p {
          margin-bottom: 10px;
          >span {
            display: inline-block;
            width: calc(100% - 53px);
            vertical-align: bottom;
            @include ellipsis;
          }
        }
      }
    }
  }
}

/* 价格计算 */
.submit-container__price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .submit-container__price__deliveryTips {
    margin-top: 13px;
    font-size: 12px;
    color: $--color-primary;
  }
  .submit-container__price__total {
    margin-top: 13px;
    font-weight: bold;
    font-size: 15px;
    span {
      font-size: 20px;
      color: #D9001B;
    }
  }
  .submit-container__price__tips {
    margin-top: 6px;
    font-size: 12px;
    color: #999999;
    >span {
      color: $--color-warning;
    }
  }
  .submit-container__price__deltime {
    margin-top: 8px;
    padding: 10px 12px;
    width: 376px;
    text-align: right;
    background: #fbfbfb;
    border: 2px solid #ffffff;
    border-radius: 22px 5px 11px 5px;
    >span {
      color: $--color-warning;
    }
  }
  .submit-container__price__btn {
    margin-top: 11px;
    padding: 11px 23px 12px;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 1px;
    &.base-button.el-button {
      border-color: #E6A23C;
      background-color: #E6A23C;
      &:focus, &:hover {
        border-color: #E6A23C;
        background-color: #E6A23C;
      }
    }
  }
  .submit-container__price__pcbaReturnTips {
    color: #c00;
  }
}
</style>
<style lang="scss">
.el-message-box.submit-container__confirmDialog {
  width: 320px;
  .el-message-box__content {
    padding: 20px 30px;
    text-align: left;
  }
}
</style>
