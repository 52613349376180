<!-- 华秋标识和制造周期 -->
<template>
  <BaseFormItem
    class="has_period"
    :label="label"
    :labelTips="fields.labelTips"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
    valueConfig="has_period"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <BaseTipPopover 
        v-for="item in options"
        :key="item.value" 
        :disabled="!item.popover"
      >
        <div v-if="item.popover" class="has_period__popover__content">
          <p>
            <span v-if="item.popover.title">{{item.popover.title}}</span>
            {{item.popover.text}}
          </p>
          <div>
            <img :src="imgs[item.popover.img]" />
          </div>
        </div>
        <el-radio-button
          slot="reference"
          :label="item.value"
        >{{ item.label }}</el-radio-button>
      </BaseTipPopover>
    </BaseRadioGroup>
  </BaseFormItem>
</template>

<script>
import { getFR4Fields, labelWarp } from '@@/utils/getFields'

const imgs = [
  'period_format_1', 'period_format_2', 'period_format_3',
  'has_period_4_1', 'has_period_4_2', 'has_period_4_3',
  'has_period_5_1', 'has_period_5_2', 'has_period_5_3'
].reduce((acc, imgName) => {
  acc[imgName] = require(`@@/assets/img/pcb/${imgName}.png`)
  return acc
}, {})

export default {
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    // 周期格式
    period_format: {
      type: [Number, String],
      default: 1
    },
    type: {
      type: String,
      validator: function (value) {
        return ['PCB', 'FPC', 'SMT', 'PCB_SMT', 'FPC_SMT', 'SMT_PCB', 'SMT_FPC'].includes(value)
      }
    }
  },
  data() {
    return {
      val: +this.value,
      imgs,
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getFR4Fields('has_period')
    },
    label() {
      // 换行展示label
      return labelWarp(this.fields.label, 4)
    },
    options() {
      const { type, fields, period_format } = this
      // FPC没有 华秋UL+周期 选项
      const options = fields.options.filter(item => type.includes('FPC') ? item.value !== 5 : true)
      return options.map(item => {
        const { popover = {}, ...other } = item
        return { ...other, popover: popover[period_format] || popover[1] || null }
      })
    }
  },
  watch: {
    value(newVal) {
      this.val = +newVal
    }
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
      this.$emit('change', val)
      this.isShowError(false)
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss">
.has_period__popover__content {
  text-align: center;
  font-size: 12px;
  >p {
    margin-bottom: 10px;
    >span {
      margin-right: 10px;
      font-weight: 700;
    }
  }
  img {
    height: 60px;
  }
}
</style>