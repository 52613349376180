<template>
  <el-dialog
    width="520px"
    top="25vh"
    class="invoice-dialog"
    :visible.sync="dialogVisible"
    :lock-scroll="false"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :onClose="handleClose">
    <span slot="title" class="invoice-dialog__title">
      {{ dialogTitle }}
      <baseTipPopover placement="right" :content="tipsList" />
    </span>
    <div>
      <el-form size="small" ref="invoiceForm" label-width="130px" label-position="right" :model="form" :rules="rules[form.invoiceType]">
        <!--  发票类型  -->
        <el-form-item label="发票类型：" prop="invoiceType">
          <base-radio-group v-model="form.invoiceType" @change="handleInvtypeChange">
            <el-radio-button :label="1" border>增值税发票</el-radio-button>
            <el-radio-button :label="2" border>电子普通发票</el-radio-button>
          </base-radio-group>
        </el-form-item>

        <!--  普票 start  -->
        <div v-if="form.invoiceType == 2">
          <el-form-item key="type" label="抬头类型：" prop="type">
            <base-radio-group v-model="form.type">
              <el-radio-button :label="1" border>公司</el-radio-button>
              <el-radio-button :label="2" border>个人/政府</el-radio-button>
            </base-radio-group>
          </el-form-item>

          <el-form-item key="invoiceTitle_2" label="发票抬头：" prop="invoiceTitle">
            <BaseInput v-model="form.invoiceTitle" placeholder="请输入发票抬头" width="310px"></BaseInput>
          </el-form-item>

          <!--  抬头类型：公司  -->
          <div v-if="form.type == 1">
            <el-form-item key="taxCode_2" label="纳税人识别号：" prop="taxCode">
              <BaseInput v-model="form.taxCode" placeholder="8、10、15、17、18、20位数字或字母组合" width="310px"></BaseInput>
            </el-form-item>

            <el-form-item key="taxAddr_2" label="地址：" prop="taxAddr">
              <BaseInput v-model="form.taxAddr" placeholder="请输入地址" width="310px"></BaseInput>
            </el-form-item>

            <el-form-item key="taxTel_2" label="电话：" prop="taxTel">
              <BaseInput v-model="form.taxTel" placeholder="请输入电话" width="310px"></BaseInput>
            </el-form-item>

            <el-form-item key="taxBank_2" label="开户行：" prop="taxBank">
              <BaseInput v-model="form.taxBank" placeholder="请输入开户行" width="310px"></BaseInput>
            </el-form-item>

            <el-form-item key="taxBankAccount_2" label="银行账号：" prop="taxBankAccount">
              <BaseInput v-model="form.taxBankAccount" placeholder="请输入银行账号" width="310px"></BaseInput>
            </el-form-item>
          </div>
          <span v-else></span>
        </div>
        <!--  普票 end  -->

        <!--  增票 start  -->
        <div v-if="form.invoiceType == 1">
          <el-form-item key="invoiceTitle_1" label="发票抬头：" prop="invoiceTitle">
            <BaseInput v-model="form.invoiceTitle" placeholder="请输入发票抬头" width="310px"></BaseInput>
          </el-form-item>

          <el-form-item key="taxCode_1" label="纳税人识别号：" prop="taxCode">
            <BaseInput v-model="form.taxCode" placeholder="8、10、15、17、18、20位数字或字母组合" width="310px"></BaseInput>
          </el-form-item>

          <el-form-item key="taxAddr_1" label="地址：" prop="taxAddr">
            <BaseInput v-model="form.taxAddr" placeholder="请输入地址" width="310px"></BaseInput>
          </el-form-item>

          <el-form-item key="taxTel_1" label="电话：" prop="taxTel">
            <BaseInput v-model="form.taxTel" placeholder="请输入电话" width="310px"></BaseInput>
          </el-form-item>

          <el-form-item key="taxBank_1" label="开户行：" prop="taxBank">
            <BaseInput v-model="form.taxBank" placeholder="请输入开户行" width="310px"></BaseInput>
          </el-form-item>

          <el-form-item key="taxBankAccount_1" label="银行账号：" prop="taxBankAccount">
            <BaseInput v-model="form.taxBankAccount" placeholder="请输入银行账号" width="310px"></BaseInput>
          </el-form-item>
        </div>
        <!--  增票 end  -->

        <el-form-item>
          <BaseButton size="small" :round="false" :loading="saveLoading" @click="handleSubmit">{{ buttonText }}</BaseButton>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>
<script>
import { addInvoice, updateInvoice } from '@@/api/customer'

export default {
  name: 'editInvoice',
  props: {
    // 发票类型：1 增票, 2 普票
    invoiceType: {
      type: [Number, String],
      default: 1,
    },
  },
  data() {
    const DEFAULT_DATA = Object.freeze({
      1: {
        invoiceType: 1, // 发票类型：1 增值税发票, 2 普通发票
        taxCode: '', // 纳税人识别号
        taxAddr: '', //　地址
        taxTel: '', // 电话
        taxBank: '', // 开户银行
        taxBankAccount: '', // 开户银行账号
        invoiceTitle: '', // 普通发票：发票抬头， 增值税发票：单位名称
        type: 1, // 抬头类型(增值税发票默认传1)
      },
      2: {
        invoiceType: 2, // 发票类型：1 增值税发票, 2 普通发票
        taxCode: '', // 纳税人识别号
        taxAddr: '', //　地址
        taxTel: '', // 电话
        taxBank: '', // 开户银行
        taxBankAccount: '', // 开户银行账号
        invoiceTitle: '', // 普通发票：发票抬头， 增值税发票：单位名称
        type: 1, // 抬头类型
      },
    })

    return {
      dialogVisible: false,
      saveLoading: false,
      alertText: {
        1: `增值税发票温馨提示：
          填写的开票信息需与贵司税务信息一致，避免因开票信息错误给贵司带来损失。
          • 增值税专用发票需按实际订单支付金额开具（含补差价金额）；优惠券减免、积分抵扣、活动折扣、赠送等金额不开具发票。
          • 增值税专用发票是单独寄出，不随着电路板订单一起发货。
          • 订单开票金额累计在2000元及以上，发票快递费由华秋电路承担--顺丰寄付`,
        2: `电子普通发票温馨提示：
          填写的开票信息需与贵司税务信息一致，避免因开票信息错误给贵司带来损失。
          • 普通（电子）发票需按实际订单支付金额开具（含补差价金额）；优惠券减免、积分抵扣、活动折扣、赠送等金额不开具发票。
          • 普通（电子）发票在签收7天后或确认订单后系统自动开票。不随着电路板订单一起发货。
          • 订单开票金额最低10元，低于10元无法开票可联系您的专属客户经理`,
      },
      type: 'add', // add 新增， edit 编辑
      defaultData: DEFAULT_DATA,
      form: { ...DEFAULT_DATA[this.invoiceType] },
      rules: {
        // 增值税发票
        1: {
          invoiceTitle: [
            { required: true, message: '请填写发票抬头', trigger: 'blur' },
            { min: 2, max: 100, message: '长度在 2 到 100 个字符', trigger: 'blur' },
          ],
          taxCode: [
            // 税号由8、10、15、17、18、20位数字或字母组合
            { required: true, message: '请填写纳税人识别号', trigger: 'blur' },
            { pattern: /^([A-Za-z0-9]{15}|[A-Za-z0-9]{20}|[A-Za-z0-9]{17,18}|[A-Z]{1}[0-9]{9}|[0-9]{8})$/, message: '8、10、15、17、18、20位数字或字母组合' },
          ],
          taxAddr: [
            { required: true, message: '请填写地址', trigger: 'blur' },
            { min: 2, max: 100, message: '长度在 2 到 100 个字符', trigger: 'blur' },
          ],
          taxTel: [
            { required: true, message: '请填写电话', trigger: 'blur' },
            { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' },
          ],
          taxBank: [
            { required: true, message: '请填写开户行', trigger: 'blur' },
            { min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur' },
          ],
          taxBankAccount: [
            { required: true, message: '请填写银行账号', trigger: 'blur' },
            { pattern: /^\d{6,30}$/, message: '请输入正确的银行卡账号' },
          ],
        },
        // 普通发票
        2: {
          type: [{ required: true, message: '请选择抬头类型', trigger: 'blur' }],
          invoiceTitle: [
            { required: true, message: '请填写发票抬头', trigger: 'blur' },
            { min: 2, max: 100, message: '长度在 2 到 100 个字符', trigger: 'blur' },
          ],
          taxCode: [
            // 税号由8、10、15、17、18、20位数字或字母组合
            { required: true, message: '请输入纳税人识别号', trigger: 'blur' },
            { pattern: /^([A-Z0-9]{15}|[A-Z0-9]{20}|[A-Z0-9]{17,18}|[A-Z]{1}[0-9]{9}|[0-9]{8})$/, message: '8、10、15、17、18、20位数字或字母组合' },
          ],
          taxAddr: [{ required: false, min: 2, max: 100, message: '长度在 2 到 100 个字符', trigger: 'blur' }],
          taxTel: [{ required: false, min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }],
          taxBank: [{ required: false, min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur' }],
          taxBankAccount: [{ required: false, pattern: /^\d{6,30}$/, message: '请输入正确的银行卡账号' }],
        },
      },
    }
  },
  computed: {
    dialogTitle() {
      return `${this.type === 'edit' ? '编辑' : '新增'}发票信息`
    },
    buttonText() {
      return this.type === 'edit' ? '保存' : '提交'
    },
    tipsList() {
      return this.alertText[this.form.invoiceType] || ''
    },
  },
  methods: {
    open(item) {
      const { invoiceType, defaultData } = this
      this.type = item ? 'edit' : 'add'
      this.$set(this, 'form', { ...defaultData[invoiceType], ...(item || {}) })

      this.$nextTick(() => {
        this.dialogVisible = true
      })
    },
    // 关闭弹框
    handleClose() {
      this.resetForm()
      this.dialogVisible = false
    },

    resetForm() {
      this.$nextTick(() => {
        this.form = {}
        // this.$refs.form.resetFields()
      })
    },

    // 发票类型改变时，重置表单数据
    handleInvtypeChange(val) {
      const { defaultData, form } = this
      this.$set(this, 'form', { ...defaultData[val], ...form })
    },

    // 提交
    handleSubmit() {
      this.$refs.invoiceForm.validate((valid) => {
        if (valid) {
          if (this.form.type == 2) {
            this.form.taxCode = ''
          }
          this.saveLoading = true

          const ApiEdit = this.type === 'edit' ? updateInvoice : addInvoice
          ApiEdit(this.form)
            .then((res) => {
              const { body, suc, msg } = res
              if (suc) {
                this.$emit('success', this.form.invoiceType, body.id || this.form.id)
                this.dialogVisible = false
                this.resetForm()
              } else {
                this.$message.error(msg)
              }
            })
            .finally(() => {
              this.saveLoading = false
            })
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.invoice-dialog {
  .invoice-dialog__title {
    line-height: 24px;
    font-size: 18px;
    color: #303133;
  }
}
</style>