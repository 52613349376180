<template>
  <el-dialog
    class="add-address-dialog"
    title="新增收货人信息"
    top="25vh"
    width="500px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    >
    <el-form size="small" ref="addressForm" label-width="120px" label-position="right" :model="form" :rules="rules">
      <el-form-item label="收货人：" prop="consignee">
        <BaseInput v-model="form.consignee" placeholder="请输入收货人" width="310px"></BaseInput>
      </el-form-item>
      <el-form-item label="收货人电话：" prop="telMobile">
        <BaseInput v-model="form.telMobile" placeholder="请输入收货人电话" width="310px"></BaseInput>
      </el-form-item>
      <el-form-item label="省市区：" prop="ssq">
        <ChooseAddressCascader v-model="ssq" width="310px" :showArea="true" @change="handleChooseAddressCascader"/>
      </el-form-item>
      <el-form-item label="详细地址：" prop="addr">
        <BaseInput v-model="form.addr" placeholder="如街道、门牌号等" width="310px"></BaseInput>
      </el-form-item>
      <el-form-item>
        <BaseButton size="small" :round="false" :loading="saveLoading" @click="handleSubmit">提交</BaseButton>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import ChooseAddressCascader from './chooseAddressCascader'
import { addNewAddressInfo } from '@@/api/customer'

const getDefaultForm = () => ({
  consignee: '',
  telMobile: '',
  addr: '',
  ssq: [],
})

export default {
  components: {
    ChooseAddressCascader
  },
  data() {
    return {
      dialogVisible: false,
      form: getDefaultForm(),
      // 省市区独立参数
      ssq: [],
      rules: {
        consignee: [
          { required: true, message: '该选项为必填项', trigger: 'blur' },
          { pattern: /^[a-zA-Z0-9\u4e00-\u9fa5]+$/, message: '输入的字符包含非法字符，请输入有效的中文、英文或数字字符' }
        ],
        telMobile: [
          { required: true, message: '该选项为必填项', trigger: 'blur' },
          { pattern: /^(1[3456789]\d{9})$|^((0|886|852)?9?\d{8})$|^([569]{1}\d{3}[-]?\d{4})$|^(0{0,2}\d{2,3}[-]?\d{7,9}(-\d{1,4})?)$/, message: '请输入正确的手机号' }
        ],
        addr: [
          { required: true, message: '该选项为必填项', trigger: 'blur' },
          { pattern: /^[a-zA-Z0-9\u4e00-\u9fa5\\-]+$/, message: '输入的字符包含非法字符，请输入有效的中文、英文或数字字符' }
        ],
        ssq: [{ type: 'array', required: true, message: '该选项为必填项', trigger: 'change' }]
      },
      saveLoading: false
    }
  },
  methods: {
    open() {
      if (this.$refs['addressForm']) {
        this.$refs['addressForm'].clearValidate()
      }
      this.form = getDefaultForm()
      this.ssq = []
      this.dialogVisible = true
    },
    handleChooseAddressCascader(data) {
      this.form.ssq = [data.province, data.city, data.district]
      this.form.province = data.province
      this.form.city = data.city
      this.form.district = data.district
      this.form.combinAddr = `${data.province_name} ${data.city_name} ${data.district_name}`
    },
    handleSubmit() {
      this.$refs['addressForm'].validate(valid => {
        if (valid) {
          this.saveLoading = true
          addNewAddressInfo(this.form)
            .then(res => {
              if (res.suc) {
                this.form.id = res.body
                this.$emit('succ', this.form)
                this.dialogVisible = false
              } else {
                this.$message.error(res.msg)
              }
              this.saveLoading = false
            })
            .catch(() => {
              this.saveLoading = false
            })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>