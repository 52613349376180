<!-- SMT 提货人 -->
<template>
  <BaseFormItem 
    class="smt-pickup" 
    label="提货人"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <BaseInput 
      v-model="val.pickup_uname" 
      class="smt-pickup--require" 
      prefix-icon="el-icon-user" 
      placeholder="姓名" 
      width="98px"
      @input="val => handleEmit('pickup_uname')"
    /> -
    <BaseInput 
      v-model="val.pickup_phone" 
      class="smt-pickup--require" 
      prefix-icon="el-icon-mobile-phone" 
      placeholder="手机号" 
      width="150px"
      @input="val => handleEmit('pickup_phone')"
    />
    <span class="smt-pickup__tips">请凭“订单号，姓名，手机号”现场提货</span>
  </BaseFormItem>
</template>

<script>
export default {
  props: {
    pickup_uname: {
      type: String,
      default: ''
    },
    pickup_phone: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      val: {
        pickup_uname: this.pickup_uname, // 提货人姓名
        pickup_phone: this.pickup_phone, // 提货人手机号
      },
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  watch: {
    pickup_uname(pickup_uname) {
      this.val.pickup_uname = pickup_uname
    },
    pickup_phone(pickup_phone) {
      this.val.pickup_phone = pickup_phone
    }
  },
  methods: {
    handleEmit(key) {
      this.$emit(`update:${key}`, this.val[key])
      this.isShowError(false)
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss" scoped>
.smt-pickup {
  color: #BBBBBB;
  .smt-pickup--require::v-deep {
    .el-input__prefix {
      &::after {
        position: absolute;
        content: "*";
        top: 0;
        left: 0;
        color: red;
      }
    }
  }
  .smt-pickup__tips {
    margin-left: 10px;
    font-size: $--font-size-small;
    color: $--color-text-placeholder;
  }
}
</style>