<!-- BOM订单信息 -->
<template>
  <div class="information">
    <div class="information__title">
      <i class="information__linker"></i>
      BOM
    </div>
    <div class="information__container">
      <InformationList :list="list">
        <!-- 采购方式 -->
        <div slot="buy_bom">
          <template v-if="data.buy_bom == 1">
            华秋帮我选型
          </template>
          <template v-else>
            自助匹配选型
            <BaseTextButton class="information__container__btn" @click="$refs.bomItemDialogRef.open(data.order_info.bom_self_id)">
              查看明细
            </BaseTextButton>
          </template>
        </div>
        <!-- PCBA返单 BOM物料来源 -->
        <div slot="buy_bom_return">
          {{ { 1: '在华秋定制', 2: '自己提供' }[data.buy_bom] || '-' }}
        </div>
      </InformationList>
    </div>
    <!-- BOM查看明细弹框 -->
    <BomItemDialog ref="bomItemDialogRef" />
  </div>
</template>

<script>
import InformationList from './list'
// BOM查看明细弹框
import BomItemDialog from '../components/bomItemDialog'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    // 是否是PCBA返单
    isPcbaReturn: {
      type: Boolean,
      default: false
    }
  },
  components: {
    InformationList,
    BomItemDialog
  },
  computed: {
    list() {
      const { isPcbaReturn, data } = this
      // PCBA返单
      if (isPcbaReturn) {
        return [
          {
            width: '303px',
            children: [{ label: 'BOM物料来源', slot: 'buy_bom_return' }]
          },
          {
          width: '303px',
          children: [{ label: { 1: '采购数量', 2: '邮寄数量' }[data.buy_bom], value: `${this.data.number}套` }]
        }
        ]
      }
      return [
        {
          width: '165px',
          children: [{ label: '采购数量', value: `${this.data.number}套` }]
        },
        {
          width: '303px',
          children: [{ label: '采购方式', slot: 'buy_bom' }]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@@/styles/mixin.scss';
.information {
  display: flex;
  padding: 0 15px 12px;

  .information__title {
    flex-shrink: 0;
    position: relative;
    box-sizing: border-box;
    margin-right: 5px;
    width: 40px;
    height: 40px;
    line-height: 38px;
    text-align: center;
    font-weight: 700;
    color: #E6A23C;
    border-radius: $--border-radius-base;
    border: 1px solid #E6A23C;
    background-color: #FEF5E4;
    .information__linker {
      @include moduleLinkerMixin(22px, horizontal, dark);
      position: absolute;
      top: -4px;
      right: -4px;
    }
  }
  .information__container {
    width: 100%;
    ::v-deep .information-list {
      padding-top: 14px;
    }
    .information__container__btn {
      margin-left: 4px;
      padding: 0 !important;
    }
  }
}
</style>