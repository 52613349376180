<!-- 工程师列表 -->
<template>
  <div>
    <RadioTable
      v-if="options.length > 0"
      v-model="val"
      radioLabel="#"
      :loading="loading"
      :height="230"
      radioKey="id"
      :columns="columns"
      :tableData="options"
      @change="handleChange"
    ></RadioTable>

    <AddDialog @success="handleSuccess" />
  </div>
</template>

<script>
import RadioTable from '@@/components/radioTable/index'
import AddDialog from './addDialog'

import { getEngineerList } from '@@/api/customer'

export default {
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    // radioKey: {
    //   type: String,
    //   default: 'id'
    // }
  },
  components: {
    RadioTable,
    AddDialog
  },
  data() {
    return {
      loading: false,
      val: this.value,
      columns: [
        { label: '姓名', prop: 'name', width: 100 },
        { label: '手机/电话', prop: 'tel', width: 120 },
        { label: 'E-mail', prop: 'email', width: 180 }
      ],
      options: []
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    }
  },
  created() {
    this.getList()
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
    },
    handleChange(item) {
      this.val = item.id || ''
      this.handleEmit()
      this.$emit('change', item)
    },
    getList() {
      this.loading = true
      getEngineerList()
        .then(res => {
          if (!res) return
          const { suc, body } = res
          if (suc) {
            this.options = body
            this.selectDefault()
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 已选的 > 默认的 > 第一个
    selectDefault() {
      const { val, options } = this
      if (options && options.length > 0) {
        const item = options.find(i => (!!val > 0 ? i.id === val : i.isDef == 1))
        this.handleChange(item || options[0])
      } else {
        this.handleChange({})
      }
    },
    handleSuccess(id) {
      this.val = id
      this.getList()
      this.$nextTick(() => {
        this.$emit('addSuccess')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>