<!-- SMT 工程师信息 -->
<template>
  <BaseFormItem class="smt-engineer" label="工程师信息">
    <BaseInput 
      v-model="val.add_user" 
      prefix-icon="el-icon-user" 
      placeholder="姓名" 
      width="98px"
      @input="val => $emit('update:add_user', val)"
    /> -
    <BaseInput 
      v-model="val.add_user_tel" 
      class="smt-engineer--require" 
      prefix-icon="el-icon-mobile-phone" 
      placeholder="电话" 
      width="150px"
      @input="val => $emit('update:add_user_tel', val)"
    /> -
    <BaseInput 
      v-model="val.add_user_email" 
      class="smt-engineer--require" 
      prefix-icon="el-icon-message" 
      placeholder="Email" 
      width="207px"
      @input="val => $emit('update:add_user_email', val)"
    />
    <p class="smt-engineer__tips">
      <BaseTextButton v-if="isShowFillBtn" :plain="false" @click="$emit('fill')">同PCB工程师</BaseTextButton>
      订单相关问题，我们将联络工程师
    </p>
  </BaseFormItem>
</template>

<script>
export default {
  props: {
    add_user: {
      type: String,
      default: ''
    },
    add_user_tel: {
      type: String,
      default: ''
    },
    add_user_email: {
      type: String,
      default: ''
    },
    isShowFillBtn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      val: {
        add_user: this.add_user, // 工程师
        add_user_tel: this.add_user_tel, // 工程师电话
        add_user_email: this.add_user_email, // 工程师邮箱
      }
    }
  },
  watch: {
    add_user(add_user) {
      this.val.add_user = add_user
    },
    add_user_tel(add_user_tel) {
      this.val.add_user_tel = add_user_tel
    },
    add_user_email(add_user_email) {
      this.val.add_user_email = add_user_email
    },
  }
}
</script>

<style lang="scss" scoped>
.smt-engineer {
  color: #BBBBBB;
  .smt-engineer--require::v-deep {
    .el-input__prefix {
      &::after {
        position: absolute;
        content: "*";
        top: 0;
        left: 0;
        color: red;
      }
    }
  }
  .smt-engineer__tips {
    margin-top: 4px;
    line-height: 1.5;
    font-size: $--font-size-small;
    color: $--color-warning;
  }
}
</style>