<!-- 《华秋SMT贴片用户协议》弹框 -->
<template>
  <el-dialog 
    center
    title="《华秋SMT贴片用户协议》"
    width="800px"
    custom-class="submit-protocol-dialog"
    :lock-scroll="false"
    :close-on-click-modal="false"
    :visible="isShow"
    @close="isShow = false"
  >
    <div class="submit-protocol-dialog__content">
      <SmtProtocol />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="isShow = false">同意并继续</el-button>
    </span>
  </el-dialog>
</template>

<script>
import SmtProtocol from './smtProtocol'
export default {
  components: {
    SmtProtocol
  },
  data() {
    return {
      isShow: false
    }
  },
  methods: {
    open() {
      this.isShow = true
    }
  }
}
</script>

<style lang="scss">
.submit-protocol-dialog {
  .el-dialog__header {
    .el-dialog__title {
      font-size: 18px;
      font-weight: 400 !important;
    }
  }

  .submit-protocol-dialog__content {
    width: 100%;
    height: 380px;
    overflow: auto;
  }
}
</style>