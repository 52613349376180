<!-- SMT合同、协议 -->
<template>
  <div class="submit-protocol">
    <el-checkbox v-model="val" @change="handleEmit" class="submit-protocol__checkbox">
      我已阅读并同意
      <BaseLink @click.prevent="$refs.smtProtocolDialogRef.open()">《华秋SMT贴片用户协议》</BaseLink>
      和
      <BaseLink @click="handleDownloadPCBAPDF">
        《PCBA销售合作协议》
      </BaseLink>
    </el-checkbox>
    <!-- 《华秋SMT贴片用户协议》弹框 -->
    <SmtProtocolDialog ref="smtProtocolDialogRef" />
  </div>
</template>

<script>
import SmtProtocolDialog from './smtProtocolDialog'

import CONFIG from '@@/config'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    pcbaPdfName: {
      type: String,
      default: ''
    }
  },
  components: {
    SmtProtocolDialog
  },
  data() {
    return {
      val: false
    }
  },
  methods: {
    handleEmit() {
      this.$emit('input', this.val)
    },
    // 《PCBA销售合作协议》 下载
    handleDownloadPCBAPDF() {
      window.open(`${CONFIG.SMTSERVICE_HOST}/online/exportPcbaContract?party_aname=${this.pcbaPdfName || ''}`, '_self')
    }
  }
}
</script>

<style lang="scss" scoped>
.submit-protocol {
  margin-top: 8px;
  .submit-protocol__checkbox ::v-deep {
    .el-checkbox__input.is-focus .el-checkbox__inner,
    .el-checkbox__inner:hover {
      border-color: $--color-primary;
    }
    .el-checkbox__input.is-checked {
      .el-checkbox__inner {
        border-color: $--color-primary;
        background-color: $--color-primary;
      }
      & + .el-checkbox__label {
        color: inherit;
      }
    }
  }
}
</style>