<!-- 费用明细弹框 -->
<template>
  <el-dialog 
    width="360px" 
    top="16%" 
    append-to-body
    custom-class="fee-fields-dialog"
    :title="`${type}费用明细`" 
    :lock-scroll="false"
    :visible="isShow" 
    @close="close"
  >
    <div class="fee-fields-dialog__content">
      <ul class="fee-fields-dialog__list">
        <template v-for="item in showFields">
          <li>
            <span>
              {{ item.label }}：
            </span>
            <span :class="{ 'has-symbol': item.symbol === '-' }">
              {{ item.symbol || '' }}
              ￥ {{ item.value }}
            </span>
          </li>
        </template>
      </ul>
    </div>
  </el-dialog>
</template>

<script>
import { PRICE_FIELDS } from '@@/utils/constant'

// 优惠字段
const HAS_SYMBOL_FIELDS = ['discount_amount', 'activity_discount_fee']

export default {
  data() {
    return {
      isShow: false,
      type: '',
      prices: {},
      otherFields: {}
    }
  },
  computed: {
    // 显示的费用字段
    showFields() {
      const { type, prices, otherFields } = this
      if (!type) return []
      const fields = {...PRICE_FIELDS[type], ...(otherFields || {})}
      return Object.keys(fields)
        .filter(key => prices[key] && prices[key] > 0)
        .map(key => {
          let label = fields[key]
          const symbol = HAS_SYMBOL_FIELDS.includes(key) ? '-' : ''
          return { label, value:  prices[key], symbol, key }
        })
    }
  },
  methods: {
    open(type, prices, otherFields) {
      this.type = type
      this.prices = prices
      this.otherFields = otherFields
      this.isShow = true
    },
    close() {
      this.isShow = false
    },
  }
}
</script>

<style lang="scss">
.fee-fields-dialog {
  $borderRadius: 4px;
  $bgColor: #fff;
  .el-dialog__header {
    padding: 9px 12px 8px;
    border-radius: $borderRadius $borderRadius 0 0;
    background-color: $bgColor;
    &::after {
      content: '';
      display: block;
      margin-top: 4px;
      width: 100%;
      border-bottom: 1px solid #ECECEC;
    }
    .el-dialog__title {
      font-size: 13px;
      color: $--color-text-primary;
    }
    .el-dialog__headerbtn {
      top: 10px;
    }
  } 
  .el-dialog__body {
    padding: 0 12px 12px;
    border-radius: 0 0 $borderRadius $borderRadius;
    background-color: $bgColor;
    color: $--color-text-primary;
  }

  .fee-fields-dialog__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    >li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      width: calc(50% - 10px);
      line-height: 28px;
      color: #666666;
      &:nth-child(2n - 1) {
        margin-right: 20px;
      }
      >span {
        &:first-child {
          &::before {
            content: '';
            display: inline-block;
            margin-right: 4px;
            width: 2px;
            height: 2px;
            border-radius: 50%;
            background-color: #666666;
            vertical-align: middle;
          }
        }
        &:last-child {
          font-weight: 700;
        }
      }
      .has-symbol {
        color: #D9001B;
      }
    }
  }
}
</style>