<!-- 返回修改按钮 -->
<template>
  <el-button
    round
    class="back-button"
    size="mini"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <i class="back-button__icon" />
    返回修改
  </el-button>
</template>

<script>
export default {
  name: 'BackButton',
  props: {
  }
}
</script>

<style lang="scss" scoped>
.back-button.el-button {
  color: #666;
  border-color: #D8D8D8;
  background-color: #fff;
  &:focus, &:hover {
    color: $--button-text-color;
    border-color: $--button-text-color;
    .back-button__icon {
      background: url('~@@/assets/img/icons/back_btn_hover.png');
    }
  }
  &.el-button--mini.is-round {
    padding: 7px 12px;
  }
  .back-button__icon {
    display: inline-block;
    width: 16px;
    height: 12px;
    vertical-align: bottom;
    background: url('~@@/assets/img/icons/back_btn.png');
  }
}
</style>
