<template>
  <div class="submit-address__flex">
    <AddressItem :address="address"/>
    <div class="submit-address-btn">
      
      <BaseTextButton v-if="address_id > 0" :plain="false" @click="handleSwitchAddress">切换地址</BaseTextButton>
      <BaseTextButton :plain="false" @click="handleAddAddress">+新增地址</BaseTextButton>
    </div>
    <SwitchAddress ref="SwitchAddress" @succ="handleChangeAddress"/>
    <AddAddress ref="AddAddress" @succ="handleChangeAddress"/>
  </div>
</template>
<script>
import SwitchAddress from '@@/components/address/switchAddress'
import AddressItem from '@@/components/address/addressItem'
import AddAddress from '@@/components/address/addAddress'
import { getNewAddressList } from '@@/api/customer'

export default {
  components: {
    AddressItem,
    SwitchAddress,
    AddAddress
  },
  prop: {
    value: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      address: {},
      address_id: 0
    }
  },
  watch: {
    address_id(newVal) {
      this.$emit('input', newVal)
    } 
  },
  methods: {
    // 切换地址
    handleSwitchAddress() {
      this.$refs['SwitchAddress'].showList(this.address_id)
    },
    // 新增地址
    handleAddAddress() {
      this.$refs['AddAddress'].open()
    },
    // 切换地址
    handleChangeAddress(addressItem) {
      this.address = addressItem
      this.address_id = addressItem.id
      this.$emit('change', addressItem)
    },
    // 获取默认地址
    init() {
      getNewAddressList({
        pageNum: 1,
        pageSize: 10
      })
        .then(res => {
          if (!res) return
          const { suc, body = [] } = res
          if (suc) {
            const filterAddrss = body.find(item => item.isDefault) || body[0]
            if (filterAddrss) {
              this.address = filterAddrss
              this.address_id = filterAddrss.id
              this.$emit('change', this.address)
            }
          }
        })
    },
  }
}
</script>
<style scoped lang="scss">
.submit-address__flex {
  display: flex;
}
.submit-address-btn {
  display: flex;
  align-items: center;
  .base-text-button {
    margin-left: 5px;
  }
}
</style>