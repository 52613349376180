<!-- 周期格式 -->
<template>
  <BaseFormItem
    class="period_format"
    :label="fields.label"
    :labelTips="fields.labelTips"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
    valueConfig="period_format"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <BaseTipPopover 
        v-for="item in fields.options"
        :key="item.value" 
        :disabled="!item.popover"
      >
        <div v-if="item.popover" class="period_format__popover__content">
          <p>
            <span v-if="item.popover.title">{{item.popover.title}}</span>
            {{item.popover.text}}
          </p>
          <div>
            <img :src="imgs[item.popover.img]" />
          </div>
        </div>
        <el-radio-button
          slot="reference"
          :label="item.value"
        >{{ item.label }}</el-radio-button>
      </BaseTipPopover>
    </BaseRadioGroup>
  </BaseFormItem>
</template>

<script>
import { getFR4Fields } from '@@/utils/getFields'

const imgs = [ 'period_format_1', 'period_format_2' ]
  .reduce((acc, imgName) => {
    acc[imgName] = require(`@@/assets/img/pcb/${imgName}.png`)
    return acc
  }, {})

export default {
  props: {
    value: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      val: this.value,
      imgs,
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getFR4Fields('period_format')
    }
  },
  watch: {
    value(newVal) {
      this.val = +newVal
    }
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
      this.$emit('change', val)
      this.isShowError(false)
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss">
.period_format__popover__content {
  text-align: center;
  font-size: 12px;
  >p {
    margin-bottom: 10px;
    >span {
      margin-right: 10px;
      font-weight: 700;
    }
  }
  img {
    height: 60px;
  }
}
</style>