// 是否显示判断
export const isShowSubmitParams = (type, pcbParams = {}, smtParams = {}) => {
  // 下单类型
  const orderType = {
    // 仅下PCB/FPC单
    onlyPcb: ['PCB', 'FPC'].includes(type),
    // 仅下SMT单
    onlySmt: type === 'SMT',
    // 有SMT单
    isNeedSmt: type.includes('SMT'),
    // PCBA返单
    isPcbaReturn: type.includes('SMT') && smtParams.order_info && !!smtParams.order_info.return_demand_sn && smtParams.order_info.return_demand_sn !== '0'
  }

  return {
    ...orderType,
    // 配送方式
    gain_order_type: orderType.isNeedSmt,
    // 提货人
    pickup: orderType.isNeedSmt && smtParams.gain_order_type == 1 && smtParams.order_info,
    // 下单人
    orderman_id: !orderType.onlySmt,
    // 一起开票
    invoice_together: !orderType.onlyPcb && !orderType.onlySmt && smtParams.order_info,
    // 发票抬头
    bill_id: pcbParams.invoice && pcbParams.invoice !== '不需要',
    // 工程师信息
    engineer_id: pcbParams.eq_notice == 2,
    // 包装要求
    hq_pack: orderType.onlyPcb,
    // 内包装标签备注
    label_remark: orderType.onlyPcb,
    // 周期格式
    period_format: pcbParams.has_period != 2,
    // 发货通知
    fh: orderType.onlyPcb,
    // 送货单
    fhp: orderType.onlyPcb,
    // PCB订单备注
    note: !orderType.isPcbaReturn,
    // PCB文件上传
    pcbfile: !orderType.isNeedSmt,
    // SMT PCB文件上传
    file_pcb: orderType.isNeedSmt && smtParams.custom_pcb_ban != 2,
    // SMT BOM文件上传
    file_bom: orderType.isNeedSmt && smtParams.buy_bom != 3,
  }
}

// 提交页PCB参数格式化
export const formatSubmitPcbParams = (type, pcbParams, otherParams = {}) => {
  const params = { ...pcbParams, ...otherParams }
  const isShow = isShowSubmitParams(type, params)

  // 包装要求
  if (!isShow.hq_pack) {
    params.hq_pack = 0
  }
  // 发货通知
  if (!isShow.fh) {
    params.fh = 0
  }
  // 送货单
  if (!isShow.fhp) {
    params.fhp = 0
  }

  return params
}

// 提交页提交订单校验
export const validateSubmit = (type, pcbParams, smtParams) => {
  const isShow = isShowSubmitParams(type, pcbParams, smtParams)
  const valids = [
    {
      key: 'pcbfile',
      flag: isShow.pcbfile && !pcbParams.pcbfile, 
      tips: '请上传PCB文件'
    },
    {
      key: 'file_pcb',
      flag: isShow.file_pcb && !smtParams.file_pcb, 
      tips: '请上传PCB文件'
    },
    {
      key: 'file_bom',
      flag: isShow.file_bom && !smtParams.file_bom, 
      tips: '请上传BOM文件'
    },
    {
      key: 'file_patch',
      flag: isShow.isNeedSmt && !smtParams.file_patch, 
      tips: '请上传SMT文件(坐标文件)'
    },
    {
      key: 'eq_notice',
      flag: !isShow.onlySmt && !pcbParams.eq_notice, 
      tips: '请选择工程EQ确认方式'
    },
    {
      key: 'engineer_id',
      flag: !isShow.onlySmt && isShow.engineer_id && (!pcbParams.engineer_id || pcbParams.engineer_id.length === 0), 
      tips: '请选择PCB工程师信息'
    },
    {
      key: 'hq_pack',
      flag: isShow.hq_pack && !pcbParams.hq_pack && pcbParams.hq_pack !== 0,
      tips: '请选择包装要求'
    },
    {
      key: 'has_period',
      flag: !isShow.onlySmt && !+pcbParams.has_period, 
      tips: '请选择华秋标识和制造周期'
    },
    {
      key: 'period_format',
      flag: !isShow.onlySmt && isShow.period_format && !+pcbParams.period_format, 
      tips: '请选择周期格式'
    },
    {
      key: 'add_user',
      flag: isShow.isNeedSmt && (!smtParams.order_info || !smtParams.order_info.add_user_tel), 
      tips: '请选择SMT工程师信息'
    },
    {
      key: 'gain_order_type',
      flag: isShow.gain_order_type && !smtParams.gain_order_type && smtParams.gain_order_type !== 0, 
      tips: '请选择配送方式'
    },
    {
      key: 'pickup',
      flag: isShow.pickup && (!smtParams.order_info || !smtParams.order_info.pickup_uname), 
      tips: '请填写提货人姓名'
    },
    {
      key: 'pickup',
      flag: isShow.pickup && (!smtParams.order_info || !smtParams.order_info.pickup_phone), 
      tips: '请填写提货人手机号'
    },
    {
      key: 'aid',
      flag: !isShow.pickup && !pcbParams.aid, 
      tips: '请选择收货地址'
    },
    {
      key: 'express',
      flag: !isShow.pickup && (!pcbParams.express || +pcbParams.express === 0), 
      tips: '请选择快递'
    },
    {
      key: 'orderman_id',
      flag: isShow.orderman_id && !pcbParams.orderman_id, 
      tips: '请选择下单人信息'
    },
    {
      key: 'invoice_together',
      flag: isShow.invoice_together && (!smtParams.order_info || !smtParams.order_info.invoice_together), 
      tips: '请选择是否一起开票'
    },
    {
      key: 'invoice',
      flag: (isShow.onlyPcb && !pcbParams.invoice) || (isShow.isNeedSmt && (!pcbParams.invoice || pcbParams.invoice === '不需要')), 
      tips: '请选择开票类型'
    },
    {
      key: 'bill_id',
      flag: isShow.bill_id && !pcbParams.bill_id, 
      tips: '请选择发票抬头'
    },
    {
      key: 'agreen_protocol',
      flag: isShow.isNeedSmt && !smtParams.agreen_protocol, 
      tips: '请先勾选同意协议再提交订单'
    },
  ]

  return valids.filter(item => {
    const flag = typeof item.flag === 'function' ? item.flag() : item.flag
    return flag
  })
}