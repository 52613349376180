<!-- 订单备注 -->
<template>
  <BaseFormItem
    class="note"
    :label="label"
  >
    <BaseInput
      v-model="val"
      type="textarea"
      width="410px"
      resize="none"
      placeholder="Pads软件默认铺铜方式为Hatch，如用其他方式请备注说明，以免出现生产问题"
      :row="3"
      @input="handleEmit"
    />
    <p class="note__tips">* 请不要将板子或钢网的加工要求填写在这</p>
  </BaseFormItem>
</template>

<script>
import { getFR4Fields, labelWarp } from '@@/utils/getFields'

export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      val: this.value
    }
  },
  computed: {
    fields() {
      return getFR4Fields('note')
    },
    label() {
      // 换行展示label
      return labelWarp(this.fields.label, 3)
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    }
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
      this.$emit('change', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.note {
  .note__tips {
    margin-top: 4px;
    line-height: 1.5;
    font-size: $--font-size-small;
    color: $--color-warning;
  }
}
</style>