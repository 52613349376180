<template>
  <div class="upload-container">
    <!-- 默认 -->
    <el-upload
      v-show="status == 0"
      class="upload-base"
      :class="{ 'is-error': isError || isErrorBlink, 'is-error-blink': isErrorBlink }"
      ref="upload-base"
      drag
      name='file'
      :accept='accept'
      :action='url'
      :headers="headers"
      :show-text="false"
      :show-file-list="false"
      :before-upload="onbeforeUpload"
      :on-progress="onProgress"
      :on-success="onSuccess"
      :on-error="onError"
      multiple>
      <img src="@@/assets/img/common/upload-img.png" class="upload-img"/>
      <p class="upload-text">拖拽文件到此，或点<span ref="upload-span">点击上传</span></p>
      <p class="upload-desc">{{ tips }}</p>
    </el-upload>
    <!-- 上传中 -->
    <div class="upload-loading" v-if="status == 1">
      <p class="loading-text">{{ name }}<span class="file-size">（{{ viewSize }}）</span></p>
      <p class="loading-desc">上传中</p>
      <el-progress :text-inside="true" :stroke-width="24" :percentage="percentage" color="#15A675" text-color="#fff" status="success"></el-progress>
    </div>
    <!-- 上传成功 -->
    <div class="upload-success" v-if="status == 2 && result == 1">
      <p class="success-text">
        <span class="file-name">{{ name }}</span>
        <span v-if="size" class="file-size">{{ viewSize }}</span>
      </p>
      <a class="success-retry-btn" href="javascript:void(0)" @click="handleRetry">重新上传</a>
      <img src="@@/assets/img/common/success-img.png" class="success-img"/>
    </div>
    <!-- 上传失败 -->
    <div class="upload-error" v-if="status == 2 && result == 0">
      <p class="error-title"><img src="@@/assets/img/common/error-img.png" class="error-img"/>上传失败</p>
      <p class="error-text">可能是网络冲突，请稍后重试</p>
      <a class="error-retry-btn" href="javascript:void(0)" @click="handleRetry" >重新上传</a>
    </div>
  </div>
</template>
<script>
import CONFIG from '@@/config'
import { getToken } from '@@/utils/auth'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'pcbfile'
    },
    accept: {
      type: String,
      default: '.zip,.rar'
    },
    limitSize: {
      type: Number,
      default: 50
    },
    tips: {
      type: String,
      default: 'Gerber或PCB文件 (zip、rar格式，小于50MB)'
    },
    fullUrl: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      headers: {
        Authorization: getToken()
      },
      status: 0, // 状态： 0-未开始, 1-上传中, 2-已完成
      result: '', // 0-失败, 1-成功
      percentage: 0, //上传进度百分比
      name: '',
      size: 0, // 单位B
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    url() {
      const { type, fullUrl } = this
      return `${CONFIG.HQPCB_HOST}/upfile?type=${type}${fullUrl ? '&full_url=1' : ''}`
    },
    viewSize() {
      const size = this.size
      let text = size + ' B'
      if (size > 1024) {
        text = parseInt(size / 1024) + ' KB'
      }
      if (size > 1024 * 1024) {
        text = parseInt(size / (1024 * 1024)) + ' MB'
      }
      return text
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.$nextTick(() => {
            this.status = 2
            this.result = 1
            this.name = value.split('/').pop()
          })
        }
      }
    }
  },
  methods: {
    // 上传前验证
    onbeforeUpload(file) {
      let isLimit = file.size / 1024 / 1024 < this.limitSize
      if (!isLimit) {
        this.$message.error(`文件大小超过限制，当前上传允许最大大小为:${this.limitSize}MB`)
      }
      this.isShowError(false)
      return isLimit
    },
    onProgress(event, file) {
      this.status = 1
      this.size = file.size
      this.name = file.name
      this.percentage = parseInt(event.percent)
    },
    onSuccess(res) {
      const { status, url, msg } = res
      if (status) {
        this.status = 2
        this.result = 1
        this.$emit('input', url)
        this.$emit('success', res)
      } else {
        this.$message.error(msg)
        this.onError()
      }
    },
    onError(e) {
      this.status = 2
      this.result = 0
      this.$emit('input', '')
    },
    handleRetry() {
      this.$refs['upload-span'].click()
      // this.$refs['upload-base'].click()
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>
<style lang="scss" scoped>
.upload-container {
  width: 360px;
}
/* 默认 */
.upload-base {
  ::v-deep {
    .el-upload {
      display: block;
    }
    .el-upload-dragger {
      width: 360px;
      height: 98px;
      background-color: #F7FCFB;
      border: 1px dashed #D7D7D7;
      border-radius: 4px;
      &:hover,
      &.is-dragover {
        border: 1px dashed $--color-success;
      }
    }
  }
  &.is-error ::v-deep .el-upload-dragger {
    border-color: $--color-danger;
    background-color: #fef0f0;
  }
  // 闪两下
  @keyframes blink {
    0% {
      border-color: rgba($color: $--color-danger, $alpha: 1.0);
      background-color: rgba($color: #fef0f0, $alpha: 1.0);
    }
    50% {
      border-color: rgba($color: $--color-danger, $alpha: 0);
      background-color: rgba($color: #fef0f0, $alpha: 0);
    }
    100% {
      border-color: rgba($color: $--color-danger, $alpha: 1.0);
      background-color: rgba($color: #fef0f0, $alpha: 1.0);
    }
  }
  &.is-error-blink ::v-deep .el-upload-dragger {
    animation: blink .8s linear .8s 2;
  }
  .upload-img {
    margin-top: 15px;
    width: 26px;
  }
  .upload-text {
    margin-top: 4px;
    height: 18px;
    line-height: 18px;
    font-size: 13px;
    color: #333;
    span {
      color: #15A675;
    }
  }
  .upload-desc {
    margin-top: 7px;
    line-height: 13px;
    height: 13px;
    font-size: 12px;
    color: #999999;
  }
}
/* 上传中 */
.upload-loading {
  width: 360px;
  height: 98px;
  padding: 12px 18px;
  background-color: #F7FCFB;
  border: 1px dashed #D7D7D7;
  border-radius: 4px;
  box-sizing: border-box;
  .loading-text {
    font-size: 13px;
    height: 18px;
    line-height: 18px;
    overflow: hidden;
    color: #333;
    white-space: nowrap;
    text-overflow: ellipsis;
    .file-size {
      color: #AAAAAA;
      display: inline-block;
    }
  }
  .loading-desc {
    margin-top: 11px;
    font-size: 13px;
    height: 18px;
    line-height: 18px;
    font-weight: bold;
    color: #333;
  }
  ::v-deep {
    .el-progress-bar {
      margin-top: 6px;
    }
    .el-progress-bar__outer {
      height: 16px!important;
    }
  }
}
/* 上传成功 */
.upload-success {
  position: relative;
  width: 360px;
  height: 98px;
  padding: 11px 17px;
  background: #f5f7fa;
  border-radius: 5px;
  box-sizing: border-box;
  .success-text {
    line-height: 18px;
    height: 44px;
    font-size: 13px;
    color: #333;
    word-break: break-all;
    overflow: hidden;
    .file-size {
      margin-top: 8px;
      display: block;
      font-size: 13px;
      color: #AAAAAA;
      line-height: 18px;
      height: 18px;
    }
  }
  .success-retry-btn {
    display: block;
    width: 72px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    color: #15A675;
    text-align: center;
    margin-top: 10px;
    background: #ffffff;
    border-radius: 12px;
  }
  .success-img {
    position: absolute;
    top: 39px;
    right: 22px;
  }
}
/* 上传失败 */
.upload-error {
  width: 360px;
  height: 98px;
  text-align: center;
  background: #FDF6EC;
  border: 1px dashed #E6A23C;
  border-radius: 5px;
  box-sizing: border-box;
  .error-title {
    margin-top: 12px;
    height: 18px;
    line-height: 18px;
    font-size: 13px;
    font-weight: bold;
    color: #FF4D4F;
    .error-img {
      margin-right: 4px;
      width: 16px;
      vertical-align: middle;
    }
  }
  .error-text {
    margin-top: 4px;
    height: 18px;
    line-height: 18px;
    color: #AAAAAA;
  }
  .error-retry-btn {
    display: inline-block;
    width: 120px;
    height: 24px;
    margin-top: 8px;
    line-height: 24px;
    font-size: 12px;
    color: #15a675;
    background: #ffffff;
    border: 1px solid #15a675;
    border-radius: 13px;
  }
}
</style>