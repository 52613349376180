<template>
  <div class="information-top">
    <BaseDescription :label="data.left.label" class="information-top__left">
      <span :style="data.left.valueStyle">
        {{ data.left.value }}
        <span v-if="data.left.tips" class="information-top__left--warning">{{ data.left.tips }}</span>
      </span>
    </BaseDescription>
    <div class="information-top__right">
      <template v-for="(item, index) in data.right">
        <BaseDescription :label="item.label">{{ item.value }}</BaseDescription>
        <span v-if="index !== data.right.length - 1">/</span>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({ left: {}, right: [] })
    }
  }
}
</script>

<style lang="scss" scoped>
.information-top {
  display: flex;
  padding: 8px 12px;
  border-radius: 5px;
  background-color: $--background-hover;
  .information-top__left {
    flex: 1;
    .information-top__left--warning {
      color: $--color-warning;
    }
  }
  .information-top__right {
    display: flex;
    >span {
      margin: 0 18px;
      color: #BBBBBB;
    }
  }
}
</style>