<!-- 送货单 -->
<template>
  <BaseFormItem
    class="fhp"
    :label="fields.label"
    :labelTips="fields.labelTips"
  >
    <BaseRadioGroup v-model="val.fhp" @change="handleFhpChange">
      <el-radio-button
        v-for="item in fields.options"
        :key="item.value"
        :label="item.value"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
    <template v-if="val.fhp === 1">
      <i class="el-icon-minus fhp__separator" />
      <!-- 收据抬头 -->
      <BaseInput 
        class="patch__input required"
        v-model="val.fhd_title" 
        width="200px"
        placeholder="请填写收据抬头"
        @input="val => handleEmit('fhd_title')"
        @change="handleEmitChange"
      >
      </BaseInput>
    </template>
  </BaseFormItem>
</template>

<script>
import { getFR4Fields } from '@@/utils/getFields'

export default {
  props: {
    // 送货单
    fhp: {
      type: Number,
      default: 0
    },
    // 收据抬头
    fhd_title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      val: {
        fhp: this.fhp,
        fhd_title: this.fhd_title
      }
    }
  },
  computed: {
    fields() {
      return getFR4Fields('fhp')
    }
  },
  watch: {
    fhp(fhp) {
      this.val.fhp = fhp
    },
    fhd_title(fhd_title) {
      this.val.fhd_title = fhd_title
    }
  },
  methods: {
    handleEmit(key) {
      this.$emit(`update:${key}`, this.val[key])
    },
    handleEmitChange() {
      this.$emit('change', { ...this.val })
    },
    handleFhpChange(val) {
      if (val === 0) {
        this.val.fhd_title = ''
      }
      this.handleEmit('fhp')
      this.handleEmit('fhd_title')
      this.handleEmitChange()
    }
  }
}
</script>

<style lang="scss" scoped>
.fhp {
  .fhp__separator {
    margin: 0 5px;
  }
}
</style>