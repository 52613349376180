<!-- SMT 配送方式 -->
<template>
  <div class="smt-gain_order_type">
    <BaseFormItem :label="fields.label">
      <BaseRadioGroup v-model="val" @change="handleEmit">
        <el-radio-button
          v-for="item in fields.options"
          :key="item.value"
          :label="item.value"
        >{{ item.label }}</el-radio-button>
      </BaseRadioGroup>
    </BaseFormItem>
    <template v-if="val == 1">
      <BaseFormItem label="提货地址">
        <div class="smt-gain_order_type__address">
          <p>华南工厂：广东省东莞市洪梅镇疏港大道4号华秋电子有限公司1栋3楼仓库办公室</p>
          <p>华中工厂：湖南省长沙市望城区航空路6号手机智能终端产业园2栋3楼仓库办公室</p>
          <p>实际提货地址以<span>最终分配</span>的生产工厂为准</p>
        </div>
      </BaseFormItem>
      <BaseFormItem label="提货时间">
        <div class="smt-gain_order_type__time">
          <span>周一至周五  15:00-18:30</span>
          <i>请提前一天与客服沟通确认自提时间</i>
        </div>
      </BaseFormItem>
    </template>
  </div>
</template>

<script>
import { getSMTFields } from '@@/utils/getFields'

export default {
  props: {
    value: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      val: this.value
    }
  },
  computed: {
    fields() {
      return getSMTFields('gain_order_type')
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    }
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
      this.$emit('change', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.smt-gain_order_type {
  .smt-gain_order_type__address {
    width: 530px;
    padding: 10px 13px;
    border-radius: 5px;
    background-color: $--background-hover;
    >p {
      line-height: 1;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
        font-size: 12px;
        color: $--color-text-placeholder;
        >span {
          color: $--color-warning;
        }
      }
    }
  }
  .smt-gain_order_type__time {
    width: 530px;
    padding: 12px 13px;
    line-height: 1;
    border-radius: 5px;
    background-color: #FDF6EC;
    >span {
      margin-right: 13px;
      color: $--color-warning;
    }
    >i {
      font-size: 12px;
      color: $--color-text-placeholder;
    }
  }
}
</style>