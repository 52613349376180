<!-- SMT订单信息 -->
<template>
  <div class="information">
    <div class="information__title">
      <i class="information__linker"></i>
      SMT
    </div>
    <div class="information__container">
      <InformationTop :data="topData" />
      <InformationList :list="list">
        <!-- PCB -->
        <div slot="custom_pcb_ban">
          <template v-if="data.custom_pcb_ban == 2">
            已下单：<BaseLink>{{ data.order_info.pcb_order_sn }}</BaseLink>
          </template>
          <template v-else>
            {{ getLabel('custom_pcb_ban') || '----' }}
          </template>
        </div>
        <!-- BOM -->
        <div slot="buy_bom">
          <template v-if="data.buy_bom == 3">
            已下单：<BaseLink>{{ data.order_info.bom_sn }}</BaseLink>
          </template>
          <template v-else>
            {{ getLabel('buy_bom') || '----' }}
          </template>
        </div>
        <!-- 样品图确认 -->
        <div slot="is_first_confirm" class="information__is_first_confirm" :class="{ 'is-mark-red': isPcbaReturn }">
          {{ getLabel('is_first_confirm') || '----' }}
        </div>
      </InformationList>
      <InformationList :list="buttonList">
        <!-- 特殊工艺 -->
        <div slot="process">
          <!-- PCBA返单 区分需要和不需要，且标红可修改的项 -->
          <template v-if="isPcbaReturn">
            <span>
              需要：
              <span v-for="(key, index) in Object.keys(processList).filter(key => data[key] == 1)">
                <template v-if="index !== 0">、</template>
                <span>
                  {{ processList[key].label }}
                </span>
              </span>
              <template v-if="Object.keys(processList).filter(key => data[key] == 1).length === 0">
                -
              </template>
              ；
            </span>
            <span>
              不需要：
              <span v-for="(key, index) in Object.keys(processList).filter(key => data[key] == 0)">
                <template v-if="index !== 0">、</template>
                <span>
                  {{ processList[key].label }}
                </span>
              </span>
              <template v-if="Object.keys(processList).filter(key => data[key] == 0).length === 0">
                -
              </template>
            </span>
          </template>
          <template v-else>
            {{ Object.keys(processList).filter(key => data[key] == 1).map(key => processList[key].label).join('、') }}
          </template>
        </div>
      </InformationList>
    </div>
  </div>
</template>

<script>
import InformationTop from './top'
import InformationList from './list'

import { list as processList } from '@@/views/smt/fields/process'
import { getLabels, getOptionsLabel } from '@@/utils/getFields'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    // 是否是PCBA返单
    isPcbaReturn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      processList
    }
  },
  components: {
    InformationTop,
    InformationList
  },
  computed: {
    labels() {
      const labels = {
        single_or_double_technique: '贴装面',
        is_plug: '插件',
        x_ray: 'X-Ray检测',
      }
      return {...getLabels('SMT'), ...labels}
    },
    fieldsFormat() {
      const data = this.data
      const getValueByKey = key => getOptionsLabel('SMT', key, data[key])
      // 需要获取选项对应的值的字段
      const valuesByOptions = ['application_sphere', 'solder_paste_type', 'packing_type']
        .reduce((acc, key) => {
          acc[key] = getValueByKey(key)
          return acc
        }, {})
      return {
        ...valuesByOptions,
        single_or_double_technique: `${getOptionsLabel('SMT', 'patch', data.single_or_double_technique)}，BOM物料${data.bom_material_type_number || '--'}种`,
        is_plug: `${getValueByKey('is_plug')}`,
        custom_pcb_ban: 'slot',
        buy_bom: 'slot',
        steel_type: `${getValueByKey('steel_type')}${data.is_steel_follow_delivery == 1 ? '，随货发' : ''}`,
        need_conformal_coating: `${getValueByKey('need_conformal_coating')}${data.is_dodge_solder_joint == 1 ? '，规避焊点及测试点' : ''}`,
        is_first_confirm: 'slot',
        x_ray: data.x_ray_unit_number && data.x_ray_number ? `${data.x_ray_unit_number}片，每片${data.x_ray_number}个元件` : '',
        process: 'slot'
      }
    },
    topData() {
      const { number, normal_dt, weight } = this.data
      return {
        left: { label: '加工数量', value: number ? `${number} Pcs` : '--' },
        right: [
          { label: '生产周期', value: normal_dt ? `${normal_dt}h` : '--' },
          { label: '重量(预估)', value: weight ? `${weight}kg` : '--' },
        ]
      }
    },
    list() {
      return [
        {
          width: '303px',
          children: ['single_or_double_technique', 'is_plug', 'application_sphere']
        },
        {
          width: '282px',
          children: ['custom_pcb_ban', 'buy_bom', 'steel_type']
        },
        {
          width: '269px',
          children: ['solder_paste_type', 'need_conformal_coating', 'is_first_confirm']
        },
        {
          width: 'auto',
          children: ['x_ray', 'packing_type']
        }
      ].map(this.format)
    },
    buttonList() {
      return [
        {
          width: '100%',
          children: ['process', 'postscript']
        },
      ].map(this.format)
    }
  },
  methods: {
    getLabel(key, value) {
      return getOptionsLabel('SMT', key, value || this.data[key])
    },
    format(item) {
      const { labels, data, fieldsFormat } = this
      item.children = item.children.map(key => ({
        label: labels[key], 
        value: key in fieldsFormat ? fieldsFormat[key] : data[key] || '----',
        slot: fieldsFormat[key] === 'slot' ? key : false
      }))
      return item
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@@/styles/mixin.scss';
.information {
  display: flex;
  padding: 0 15px 12px;

  .information__title {
    flex-shrink: 0;
    position: relative;
    box-sizing: border-box;
    margin-right: 5px;
    width: 40px;
    height: 40px;
    line-height: 38px;
    text-align: center;
    font-weight: 700;
    color: #409eff;
    border-radius: $--border-radius-base;
    border: 1px solid #409eff;
    background-color: #ecf4fc;
    .information__linker {
      @include moduleLinkerMixin(22px, horizontal, dark);
      position: absolute;
      top: -4px;
      right: -4px;
    }
  }
  .information__container {
    flex: 1;
    >div {
      & + div {
        margin-top: 2px;
      }
    }
  }
  .information__btn {
    margin-top: 5px;
    text-align: center;
  }

  .information__is_first_confirm {
    color: $--color-warning;
  }
}
</style>