<template>
  <el-dialog
    class="switch-address-dialog"
    title="切换地址"
    top="15vh"
    width="720px"
    :show-close="false"
    :append-to-body="true"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    >
    <el-scrollbar class="switch-address-scrollbar">
      <div class="switch-address-list" v-loading="loading">
        <div class="address-item" v-for="(item, index) in addressList" :class="chooseId == item.id ? 'active' : ''" @click="handleChoose(item)" :key="index">
          <div class="address-item-icon">
            <img src="@@/assets/img/common/address-local.png" class="icon-img">
          </div>
          <div class="address-item-content">
            <div class="address-title">
              <span class="address-user-name">{{ item.consignee }}</span>
              <span class="address-user-phone">{{ item.telMobile }}</span>
              <span class="address-default" v-show="item.isDefault">[默认]</span>
            </div>
            <p class="address-detail">{{ item.combinAddr }}{{ item.addr }}</p>
          </div>
        </div>
      </div>
    </el-scrollbar>
    <div class="switch-address-btn">
      <a href="javascript:void(0)" class="btn-confirm" @click="handleConfrim">确定</a>
      <a href="javascript:void(0)" class="btn-cancel" @click="handleCancel">取消</a>
    </div>
  </el-dialog>
</template>
<script>
import {
  getNewAddressList
} from '@@/api/customer'

export default {
  data() {
    return {
      dialogVisible: false,
      addressList: [],
      chooseId: '',
      chooseItem: null,
      loading: false
    }
  },
  methods: {
    getAddressList() {
      this.loading = true
      getNewAddressList({
        pageNum: 1,
        pageSize: 100
      })
        .then(res => {
          if (res.suc) {
            this.addressList = res.body
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    showList(chooseId) {
      this.chooseId = chooseId || ''
      this.dialogVisible = true
      this.getAddressList()
    },
    handleChoose(item) {
      this.chooseItem = item
      this.chooseId = item.id
    },
    handleConfrim() {
      this.$emit('succ', this.chooseItem)
      this.dialogVisible = false
    },
    handleCancel() {
      this.dialogVisible = false
    }
  }
}
</script>
<style lang="scss" scoped>
.switch-address-dialog {
  ::v-deep .el-dialog__body {
    padding: 0 20px;
  }
}
.switch-address-scrollbar {
  height: 500px;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
/* .switch-address-list {
  padding-bottom: 10px;
} */
.address-item {
  display: flex;
  width: 680px;
  min-height: 66px;
  background: #fff;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  box-sizing: border-box;
  margin-bottom: 10px;
  cursor: pointer;
  overflow: hidden;
  .address-item-icon {
    padding: 15px 13px 0 15px;
    .icon-img {
      width: 22px;
      filter: drop-shadow(#d7d7d7 -1000px 0);
      transform: translateX(1000px);
    }
  }
  &.active {
    background-color: #F7FCFB;
    border: 1px solid #15a675;
    .icon-img {
      transform: translateX(0);
    }
  }
  .address-item-content {
    font-size: 13px;
    color: #333333;
    min-height: 18px;
    line-height: 18px;
    padding: 10px 0;
    .address-title {
      display: flex;
      color: #999999;
      .address-user-name {
        margin-right: 8px;
      }
      .address-default {
        margin-left: auto;
        margin-right: 10px;
      }
    }
    .address-detail {
      margin-top: 5px;
      margin-right: 20px;
    }
  }
}
.switch-address-btn {
  padding: 20px 0;
  text-align: center;
  .btn-confirm,
  .btn-cancel {
    display: inline-block;
    margin: 0 10px;
    width: 66px;
    height: 32px;
    font-size: 13px;
    line-height: 32px;
    border-radius: 16px;
    text-align: center;
    box-sizing: border-box;
  }
  .btn-confirm {
    border: 1px solid #15a675;
    background-color: #15a675;
    color: #fff;
  }
  .btn-cancel {
    border: 1px solid #d7d7d7;
    background-color: #fff;
    color: #15a675;
  }
}
</style>