<template>
  <!-- 快递公司 -->
  <BaseFormItem 
    label="快递公司" 
    isShowRequiredIcon
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <div class="express-container">
      <ExpressList 
        ref="express"
        v-bind="$attrs"
        v-on="$listeners"
        @change="handleChange"
      />
    </div>
  </BaseFormItem>
</template>

<script>
// 快递公司
import ExpressList from '@@/components/expressSelect/list'
export default {
  components: {
    ExpressList
  },
  data() {
    return {
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  methods: {
    handleChange(...data) {
      this.$emit('change', ...data)
      this.isShowError(false)
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss" scoped>
.express-container {
  width: 360px;
}
</style>