<!-- 发票列表 -->
<template>
  <div class="invoice">
    <BaseSelect 
      v-model="val" 
      width="233px"
      popper-class="invoice__select"
      placeholder="请选择"
      @change="handleChange"
    >
      <div class="el-select-group__title invoice__select__option">
        <p>发票抬头</p>
        <p>纳税人识别号</p>
      </div>
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="item.invoiceTitle"
        :value="item.id"
      >
        <div class="invoice__select__option">
          <p class="invoiceTitle">{{ item.invoiceTitle }}</p>
          <p class="taxCode">{{ item.taxCode }}</p>
        </div>
      </el-option>
    </BaseSelect>
    <span slot="other">
      <BaseTextButton class="invoice__btn" @click="$refs.editInvoiceRef.open()">
        +新增发票
      </BaseTextButton>
      <BaseLink @click="handleManageInvoice">
        管理抬头
      </BaseLink>
      <!-- 新增发票弹框 -->
      <EditInvoice 
        ref="editInvoiceRef" 
        :invoiceType="type" 
        @success="handleEditInvoice"
      />
    </span>
  </div>
</template>

<script>
import EditInvoice from './editInvoice'
import CONFIG from '@@/config'
import { isPcbSite, isSmtSite } from '@@/utils/is'
import { getInvoiceList } from '@@/api/customer'

export default {
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    // 发票类型：1-增票 2-普票
    type: {
      type: Number,
      default: 2
    }
  },
  components: {
    EditInvoice
  },
  data() {
    return {
      loading: false,
      val: this.value,
      invoiceType: this.type,
      options: []
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal || ''
    },
    type(type) {
      this.invoiceType = type
      this.getList()
    }
  },
  created() {
    this.getList()
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
    },
    handleChange() {
      this.handleEmit()
      const item = this.options.find(i => (!!this.val > 0 ? i.id === this.val : i.isDef == 1))
      this.$emit('change', item ? {...item} : undefined)
    },
    getList() {
      this.loading = true
      getInvoiceList({ invoiceType: this.invoiceType })
        .then(res => {
          if (!res) return
          const { suc, body } = res
          if (suc) {
            this.options = body ? body : []
            this.selectDefault()
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 已选的 > 默认的 > 第一个
    selectDefault() {
      const { val, options } = this
      if (options && options.length > 0) {
        const item = options.find(i => (!!val ? i.id === val : i.isDef == 1))
        this.val = item ? item.id : options[0].id
        this.handleChange()
      } else {
        this.val = ''
        this.handleChange()
      }
    },
    // 新增发票成功
    handleEditInvoice(type, id) {
      this.invoiceType = type
      this.val = id
      this.getList()
    },
    // 管理抬头
    handleManageInvoice() {
      if (isPcbSite) {
        window.open('/mycenter/finance/invoice/mylist')
      } else if (isSmtSite) {
        window.open(`${CONFIG.HQCHIP_HOST}/mycenter/finance/invoice/mylist`)
      } else {
        this.$router.push('/finance/invoice/mylist')
      }
    }
  }
}
</script>

<style lang="scss">
@import '@@/styles/mixin.scss';
.invoice__select {
  width: 350px;
  .invoice__select__option {
    display: flex;
    >p {
      @include ellipsis;
      &:first-child {
        width: 40%;
      }
      &:last-child {
        margin-left: 20px;
        flex: 1;
      }
    }
  }
}
.invoice__btn {
  margin: 0 10px;
}
</style>