<!-- 内包装标签备注 -->
<template>
  <BaseFormItem
    class="label_remark"
    :label="label"
    :labelTips="fields.labelTips"
  >
    <BaseInput
      v-model="val"
      width="186px"
      class="label_remark__input"
      placeholder="不超过10个文字, 或15个数字"
      @input="val => $emit('input', val)"
      @change="val => $emit('change', val)"
    ></BaseInput>
  </BaseFormItem>
</template>

<script>
import { getFR4Fields, labelWarp } from '@@/utils/getFields'

export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      val: this.value
    }
  },
  computed: {
    fields() {
      return getFR4Fields('label_remark')
    },
    label() {
      // 换行展示label
      return labelWarp(this.fields.label, 3)
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    }
  }
}
</script>

<style lang="scss" scoped>
.label_remark__input ::v-deep {
  .el-input__inner::placeholder {
    font-size: 12px;
  }
}
</style>