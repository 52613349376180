<template>
  <div class="address-item" v-if="address.consignee">
    <div class="address-item-icon">
      <img src="@@/assets/img/common/address-local.png" class="icon-img">
    </div>
    <div class="address-item-content">
      <div class="address-title">
        <span class="address-user-name">{{ address.consignee }}</span>
        <span class="address-user-phone">{{ address.telMobile }}</span>
      </div>
      <p class="address-detail" >{{ address.combinAddr }}{{ address.addr }}</p>
    </div>
    <span class="address-default" v-show="address.isDefault">[默认]</span>
  </div>
</template>
<script>
export default {
  props: {
    address: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
<style lang="scss" scoped>
.address-item {
  position: relative;
  flex-shrink: 0;
  display: flex;
  width: 688px;
  min-height: 44px;
  border-radius: 5px;
  border: 1px dashed #d8d8d8;
  padding: 10px 0;
  cursor: pointer;
  .address-item-icon {
    padding: 5px 13px 0 15px;
    .icon-img {
      width: 22px;
    }
  }
  .address-item-content {
    font-size: 13px;
    color: #333333;
    line-height: 18px;
    .address-title {
      display: flex;
      color: #999999;
      .address-user-name {
        margin-right: 8px;
      }
      
    }
    .address-detail {
      margin-top: 5px;
      margin-right: 20px;
    }
  }
  .address-default {
    position: absolute;
    top: 4px;
    right: 10px;
    color: #999999;
  }
}
</style>