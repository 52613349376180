<!-- SMT文件展示 -->
<template>
  <div class="smt-file-view">
    <!-- PCB文件 -->
    <div class="smt-file-view__item">
      <p>
        PCB文件
        <BaseTipPopover 
          :content="`
            1、单层和双层板提供标准文件，工程费将优惠20或30元 (订单审核时会减去)\n
            2、标准文件的定义：上传的文件中不含有除PCB文件以外的说明文档，且订单备注没有填写生产加工要求
          `" 
        />
      </p>
      <div>
        <p>
          <span>
            <a
              target="_blank"
              :title="data.pcb_file_name" 
              :href="data.file_pcb" 
            >
              {{ data.pcb_file_name }}
            </a>
          </span>
        </p>
      </div>
    </div>
    <!-- BOM文件 -->
    <div class="smt-file-view__item">
      <p>BOM文件</p>
      <div>
        <p>
          <span>
            <i v-if="data.buy_bom == 4">原始</i>
            <a
              target="_blank"
              :title="data.bom_file_name" 
              :href="data.file_bom" 
            >
              {{ data.bom_file_name }}
            </a>
          </span>
        </p>
        <p v-if="data.buy_bom == 4">
          <span>
            <i>匹配</i>
            <a :href="downloadBomUrl" target="_blank">BOM匹配后文件.xls</a>
          </span>
        </p>
      </div>
    </div>
    <!-- 坐标文件 -->
    <div class="smt-file-view__item">
      <p>SMT文件(坐标文件)</p>
      <div>
        <p>
          <span>
            <i v-if="isEditSmtFile">原始</i>
            <a
              target="_blank"
              :title="data.smt_file_name" 
              :href="data.file_patch" 
            >
              {{ data.smt_file_name }}
            </a>
          </span>
        </p>
        <p v-if="isEditSmtFile">
          <span>
            <i>修改</i>
            <a :href="downloadSmtUrl" target="_blank">SMT修改后文件.xls</a>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CONFIG from '@@/config'
import { getToken } from '@@/utils/auth'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    // 是否有SMT修改坐标文件
    isEditSmtFile() {
      return this.data.order_info && this.data.order_info.is_edit_smt_file
    },
    // 下载BOM匹配文件
    downloadBomUrl() {
      return `${CONFIG.HQPCBA_HOST}/pcba/app/bom/downloadBomMatchItem?oneStopBomId=${this.data.order_info.bom_self_id}&token=${getToken()}`
    },
    // 下载SMT修改文件
    downloadSmtUrl() {
      return `${CONFIG.HQPCBA_HOST}/pcba/app/bom/downloadBomMatchSmt?oneStopBomId=${this.data.order_info.bom_self_id}&token=${getToken()}`
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@@/styles/mixin.scss';
.smt-file-view {
  display: flex;
  padding: 0 23px 19px;
  .smt-file-view__item {
    margin-right: 60px;
    width: 320px;
    >p {
      margin-bottom: 8px;
      font-weight: 700;
    }
    >div {
      height: 70px;
      >p {
        max-width: 100%;
        &:first-child {
          margin: 16px 0 20px;
        }
        >span {
          position: relative;
          display: inline-block;
          max-width: 100%;
          >i {
            position: absolute;
            top: -8px;
            right: -34px;
            display: block;
            width: 32px;
            height: 16px;
            line-height: 16px;
            text-align: center;
            font-size: 12px;
            color: #fff;
            border-radius: 2px;
            background-color: $--color-warning;
          }
          >a {
            display: block;
            color: $--form-color-base;
            @include ellipsis;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
</style>