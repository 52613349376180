<template>
  <!-- 发票抬头 -->
  <BaseFormItem 
    label="发票抬头"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <InvoiceList 
      ref="bill_id"
      v-bind="$attrs"
      v-on="$listeners"
      @change="handleChange"
    />
  </BaseFormItem>
</template>

<script>
// 发票列表
import InvoiceList from '@@/components/invoice/index'
export default {
  components: {
    InvoiceList
  },
  data() {
    return {
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  methods: {
    handleChange(...data) {
      this.$emit('change', ...data)
      this.isShowError(false)
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss" scoped>
</style>