<template>
  <el-dialog
    class="add-order-man-dialog"
    title="新增下单人"
    top="25vh"
    width="500px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    >
    <el-form size="small" ref="orderManForm" label-width="120px" label-position="right" :model="form" :rules="rules">
      <el-form-item label="下单人姓名：" prop="orderMan">
        <BaseInput v-model="form.orderMan" placeholder="请填写姓名" width="310px"></BaseInput>
      </el-form-item>
      <el-form-item label="下单人电话：" prop="orderTel">
        <BaseInput v-model="form.orderTel" placeholder="请输入手机号码" width="310px"></BaseInput>
      </el-form-item>
      <el-form-item>
        <BaseButton size="small" :round="false" :loading="saveLoading" @click="handleSubmit">提交</BaseButton>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import { addOrderMan } from '@@/api/customer'

const getDefaultForm = () => ({
  orderMan: '',
  orderTel: ''
})

export default {
  data() {
    return {
      dialogVisible: false,
      form: getDefaultForm(),
      rules: {
        orderMan: [
          { required: true, message: '该选项为必填项', trigger: 'blur' },
          { pattern: /^[a-zA-Z0-9\u4e00-\u9fa5]+$/, message: '输入的字符包含非法字符，请输入有效的中文、英文或数字字符' }
        ],
        orderTel: [
          { required: true, message: '该选项为必填项', trigger: 'blur' },
          { pattern: /^(1[3456789]\d{9})$|^((0|886|852)?9?\d{8})$|^([569]{1}\d{3}[-]?\d{4})$|^(0{0,2}\d{2,3}[-]?\d{7,9}(-\d{1,4})?)$/, message: '请输入正确的格式' }
        ]
      },
      saveLoading: false
    }
  },
  methods: {
    open() {
      if (this.$refs['orderManForm']) {
        this.$refs['orderManForm'].clearValidate()
      }
      this.form = getDefaultForm()
      this.dialogVisible = true
    },
    handleCancel() {
      this.dialogVisible = false
    },
    handleSubmit() {
      this.$refs['orderManForm'].validate(valid => {
        if (valid) {
          this.saveLoading = true
          addOrderMan(this.form)
            .then(res => {
              if (res.suc) {
                this.$emit('succ', res.body.id)
                this.dialogVisible = false
              } else {
                this.$message.error(res.msg)
              }
              this.saveLoading = false
            })
            .catch(() => {
              this.saveLoading = false
            })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>