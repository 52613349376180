<!-- 下单人 -->
<template>
  <BaseFormItem
    class="orderman_id"  
    :label="fields.label"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <BaseSelect 
      v-model="val" 
      width="224px"
      popper-class="orderman_id__select"
      placeholder="请选择"
      @change="handleChange"
    >
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="item.orderMan"
        :value="item.id"
      >
        <div class="orderman_id__option">
          <p>
            <span>{{ item.orderMan }}</span>
            <span>{{ item.orderTel }}</span>
          </p>
          <span v-if="item.isDefault === 1">[默认]</span>
        </div>
      </el-option>
    </BaseSelect>
    <div slot="other">
      <BaseTextButton :plain="false" @click="$refs.addOrderManRef.open()">
        +新增下单人
      </BaseTextButton>
      <!-- 新增下单人弹框 -->
      <AddOrderMan ref="addOrderManRef" @succ="handleChangeOrderMan"/>
    </div>
  </BaseFormItem>
</template>

<script>
import AddOrderMan from '@@/views/stencil/components/addOrderMan'

import { getFR4Fields } from '@@/utils/getFields'
import { getOrderManPage } from '@@/api/customer'

export default {
  props: {
    value: {
      type: [Number, String],
      default: ''
    }
  },
  components: {
    AddOrderMan
  },
  data() {
    return {
      val: this.value,
      options: [],
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getFR4Fields('orderman_id')
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    }
  },
  // mounted() {
  //   this.val = ''
  //   this.getList()
  // },
  methods: {
    init() {
      this.getList()
    },
    handleEmit() {
      this.$emit('input', this.val)
      this.isShowError(false)
    },
    handleChange(id) {
      this.val = id
      this.handleEmit()
      this.$emit('change', this.options.find(i => i.id == id) || {})
    },
    getList() {
      getOrderManPage({ pageNum: 1, pageSize: 100 })
        .then(res => {
          if (!res) return
          const { suc, body = [], msg } = res
          if (suc) {
            this.options = body
            this.selectDefault()
          } else {
            this.$message.error(msg)
          }
        })
    },
    // 当有已选时，选中已选的，否则选中第一个
    selectDefault() {
      const { val, options } = this
      if (options && options.length > 0) {
        const item = val && options.find(i => i.id == val)
        this.handleChange(item ? item.id : options[0].id)
      }
    },
    // 新增下单人
    handleChangeOrderMan(id) {
      this.val = id
      this.getList()
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss">
@import '@@/styles/mixin.scss';
.orderman_id__select {
  .orderman_id__option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 260px;
    >p {
      width: calc(100% - 32px);
      display: flex;
      >span {
        &:first-child {
          flex: 1;
          @include ellipsis;
        }
        &:last-child {
          margin-left: 20px;
          width: 130px;
        }
      }
    }
    >span {
      font-size: 12px;
      font-weight: 400;
      color: $--color-text-disabled;
    }
  }
}
</style>