<!-- 添加工程师弹框 -->
<template>
  <div>
    <BaseTextButton :plain="false" @click="open">
      +新增一个
    </BaseTextButton>

    <el-dialog
      title="工程师信息"
      top="25vh"
      width="400px"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      >
      <el-form size="small" ref="engineerFormRef" label-width="120px" label-position="right" :model="form" :rules="rules">
        <!-- 姓名 -->
        <el-form-item label="姓名：" prop="name">
          <BaseInput v-model="form.name" :maxlength="20"></BaseInput>
        </el-form-item>
        <!-- 手机/电话 -->
        <el-form-item label="手机/电话：" prop="tel">
          <BaseInput v-model="form.tel"></BaseInput>
        </el-form-item>
        <!-- E-mail -->
        <el-form-item label="E-mail：" prop="email">
          <BaseInput v-model="form.email"></BaseInput>
        </el-form-item>

        <el-form-item>
          <BaseButton size="small" :round="false" :loading="saveLoading" @click="handleSubmit">提交</BaseButton>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { addEngineer } from '@@/api/customer'

const getDefaultForm = () => ({
  name: '', // 工程师名称
  tel: '', // 工程师电话
  email: '', // 工程师邮箱
})

export default {
  data() {
    return {
      dialogVisible: false,
      saveLoading: false,
      form: getDefaultForm(),
      rules: {
        name: [
          { required: true, message: '请输入工程师姓名', trigger: 'blur' },
          { pattern: /^[a-zA-Z0-9\u4e00-\u9fa5]+$/, message: '输入的字符包含非法字符，请输入有效的中文、英文或数字字符' }
        ],
        tel: [
          { required: true, message: '请输入工程师手机/电话', trigger: 'blur' },
          { pattern: /^(1\d{10})$|^((0|886|852)?9?\d{8})$|^([569]{1}\d{3}[-]?\d{4})$|^(0{0,2}\d{2,3}[-]?\d{7,9}(-\d{1,4})?)$/, message: '请输入正确的手机/电话'}
        ],
        email: [
          { required: true, message: '请输入工程师邮箱', trigger: 'blur' },
          { pattern: /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/, message: '请输入正确的邮箱'}
        ]
      }
    }
  },
  methods: {
    open() {
      if (this.$refs['engineerFormRef']) {
        this.$refs['engineerFormRef'].clearValidate()
      }
      this.form = getDefaultForm()
      this.dialogVisible = true
    },
    handleCancel() {
      this.dialogVisible = false
    },
    handleSubmit() {
      this.$refs['engineerFormRef'].validate(valid => {
        if (valid) {
          this.saveLoading = true
          addEngineer(this.form)
            .then(res => {
              if (res.suc) {
                this.$emit('success', res.body)
                this.dialogVisible = false
              } else {
                this.$message.error(res.msg)
              }
              this.saveLoading = false
            })
            .catch(() => {
              this.saveLoading = false
            })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>