<template>
  <div class="agreement-text">
    <h1>本协议是客户（以下简称“您”）与深圳华秋电子有限公司（以下简称“我司”）就SMT贴片&DIP PCBA加工服务等相关事宜共同缔结。感谢您选择深圳华秋电子，我们将竭诚为您服务。为了更好的为您提供高效优质的服务，以下涉及到SMT业务准时交付，质量成本等方面的事宜，还请您特别知晓，并按要求落实。由此给您带来的不便，敬请谅解，感谢您的理解与支持！本协议类同合同效力，请您务必审慎阅读、充分理解各条款内容并同意。当您下达订单视同同意此约定，即构成对双方有约束力的法律文件。</h1>
    <ul>
        <li><h2>一．关于免责条款</h2></li>
        <li><span>1.</span><em>客供物料不齐，但客户要求上线生产的情况，我司不提供免费手工焊料服务，客户自己提供的物料或由客户指定供应商提供的物料，若在生产过程中或在交付后出现任何品质或不良或返工问题，如PCB断线、物料假货、由物料封装不对导致补料及交期延误、由物料氧化造成的不上锡/易脱落、由来料管脚变形等造成虚焊或短路、由来料问题导致的返工等问题，我司不承担任何责任，不提供免费维修服务。</em></li>
        <li><span>2.</span><em>若生产过程中出现异常，无法联系到客户相关人员导致异常无法确认的，我司有权自行处理异常情况，但不承担因异常处理导致的相关责任。</em></li>
        <li><span>3.</span><em>因客户提供的技术资料、生产文件、样机或样机图示导致的贴装及焊接错误，我司不承担任何责任。</em></li>
        <li><span>4.</span><em>因客户设计、客供物料损坏、物料与焊盘不匹配等非我司导致的问题，后续需要我司另行提供生产或售后维修的，我司仅提供有偿服务；非我司原因需要提供生产或售后维修引发的品质风险、物料报废、产品报废等，需要客户同意免责后，我司再安排相关作业。</em></li>
        <li><span>5.</span><em>我司不承担客供物料有效期超期或标识不清无法辨识、温湿度敏感元件未作真空包装防护受潮导致无铅回流焊接（230℃~260℃）物料损坏的相关责任。</em></li>
        <li><span>6.</span><em>客户提供的插件物料需要能够承受150℃的波峰焊接预热温度，若不能满足上述条件导致的不良，我司不承担任何责任。</em></li>
        <li><span>7.</span><em>温湿度敏感器件BGA/QFP/QFN，客户提供的贵重芯片需使用行业标准的托盘包装，我司进行正常烘烤作业。如客户提供的芯片包装不符行业标准，或对部分芯片有特殊要求需要烘烤导致的芯片不良，我司不承担物料损坏的责任，包装材料不能承受150℃烘烤条件的不安排烘烤，物料温湿度等级无明确可辨识的标签而造成没有安排烘烤的，我司不承担材料性能问题。</em></li>
    </ul>
    <ul>
        <li><h2>二．关于SMT加工要求</h2></li>
        <li><span>1.</span><em>常规要求</em></li>
        <li>
            <table cellpadding="0" cellspacing="0" width="100%">
                <tr>
                    <td width="30">序号</td>
                    <td>项目</td>
                    <td width="360">内容描述</td>
                    <td>备注</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>PCB尺寸</td>
                    <td>50*50mm — 460*450mm，建议尺寸小、元件少PCB拼板</td>
                    <td rowspan="6" align="center">不在规格内需要评估</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>PCB厚度</td>
                    <td>0.3mm — 4.5mm</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>元件尺寸</td>
                    <td>01005 — 45mm*98mm</td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>元件间距</td>
                    <td>IC最小间距0.25mm，BGA最小间距0.25mm（中心到中心距离）</td>
                </tr>
                <tr>
                    <td>5</td>
                    <td>元件高度</td>
                    <td>最高19mm</td>
                </tr>
                <tr>
                    <td>6</td>
                    <td>贴片精度</td>
                    <td>chip类 ±41µm ,IC类±37.5µm</td>
                </tr>
                <tr>
                    <td>7</td>
                    <td>流板要求</td>
                    <td>PCB为矩形，焊盘到板边尺寸大于3mm，否则需要加工艺边</td>
                    <td rowspan="6" align="left">不符合要求会给生产带来困扰和不便</td>
                </tr>
                <tr>
                    <td>8</td>
                    <td>基准点</td>
                    <td>PCB上不少于一对mark点，mark点距离板边大于3mm</td>
                </tr>
                <tr>
                    <td>9</td>
                    <td>生产资料</td>
                    <td>正确的BOM、坐标、钢网文件（拼板）、图纸文件，特殊工艺要求，样机或高清样品图（提供样机或高清样品图，请说明清楚是仅供参考，还是完全按样机或图片）
                    </td>
                </tr>
                <tr>
                    <td>10</td>
                    <td>PCB表面工艺</td>
                    <td>0201\BGA\引脚宽度0.2mm以下产品小批量打样使用化学沉金工艺，避免焊盘氧化问题导致的焊接不良</td>
                </tr>
                <tr>
                    <td>11</td>
                    <td>客供钢网</td>
                    <td>客户提供钢网尺寸必须在370*470mm - 736*736mm范围内</td>
                </tr>
                <tr>
                    <td>12</td>
                    <td>三防漆</td>
                    <td>需要提供三防漆区域图示，不能有三防漆的器件位置号说明</td>
                </tr>
            </table>
        </li>
        <li><span>2.</span><em>其他要求</em></li>
        <li>
            <span>a.</span>
            <em>
                <b>关于焊盘和通孔上锡</b>
                SMT贴片焊盘默认钢网开口上锡，不上锡需要明确位置号说明，必要时辅以图片说明，DIP插件/后焊的PCB孔在做波峰焊制程时会默认上锡堵孔，客户需要自己补焊物料或固件烧录用到的孔，对于不能上锡的孔需要明确给出位置号说明，必要时辅以图示说明，螺丝孔、金手指等行业标准做法不需要说明。            </em>
            </li>
        <li>
            <span>b.</span>
            <em>
                <b>关于焊接温度</b>
                所有产品默认为无铅焊接制程，SMT回流焊实际焊接温度235~250℃、波峰焊接温度260~265℃、波峰预热温度110~130℃。温度敏感器件，请在工艺文件注明要求，并提供材料焊接规格书。
            </em>
        </li>
        <li>
            <span>c.</span>
            <em>
                <b>关于焊接设备及条件</b>
                所有产品默认普通SMT回流焊，DIP器件根据产品批量情况选择常规波峰焊接、烙铁手工焊接，焊接材料为锡膏成分为无铅SAC305，锡条、锡线无无铅焊锡，如有需要SMT回流焊氮气焊接、选择性波峰焊接、不允许烙铁手工焊接、不能使用无铅焊接材料等特殊要求，请与您的客服专员联系沟通。</em>
        </li>
        <li>
            <span>d.</span>
            <em>
                <b>关于PCBA洁净度</b>
                所有PCBA洁净度默认行业普通要求，默认客户自己组装产品使用。如果您的产品对于PCBA外观要求高，裸板出货的，请与您的客服专员沟通，订单备注裸板出货。如果对于PCBA有洁净度要求，需要提供离子浓度测试的，请先于您的客服专员沟通。
            </em>
        </li>
        <li>
            <span>e.</span>
            <em>
                <b>关于BOM</b>
                所有材料准备、生产作业都是依据BOM来作业的，请您在订单确认付款时，再次确认产品BOM为最新当前生产的版本。所有的产品材料、配件、辅材都包含在BOM单里，BOM的每一行只对应一种物料，不能包含组件（比如LED灯、灯柱，需要两行来描述），避免物料准备、生产作业中遗漏，耽误交期或造成作业漏失出货。
            </em>
        </li>
        <li>
            <span>f.</span>
            <em style="color:red;">
                关于订单数量的说明。订单数量以单PCS计算，不是以1拼板计算为1片。客户在下单时注意理解订单数据，有任何问题可以联系您的专属客服确认，避免耽搁交期和出货。
            </em>
        </li>
        <li>
            <span>g.</span>
            <em style="color:red;">
                PCB材质为玻纤类硬板、纸质类硬板：1.请对于FPC类的软板、或软硬结合板，铝基板，在订单下达前知会您的客服专员。2.如贵司提供的OSP工艺的PCB，涉及到对PCB板焊接时效等相关管控要求时，务必要求供应商妥善防护（真空密封并配湿度指示卡），且在订单下达时知会您的客服专员。
            </em>
        </li>
        <li>
            <span>h.</span>
            <em style="color:red;">
                关于治工具处理的说明。在产品交付后3个月内未使用的治工具，我司将直接做报废处理。如您要求回收治工具的，请在下单时一并告知您的客服专员，并应额外支付邮寄费，否则我司自行处理后不承担任何赔偿责任。
            </em>
        </li>
        <li><span>3.</span><em>关于生产资料完整性与排单</em></li>
        <li>以接收到客户物料后，清点齐套、焊盘核对无误，无品质异常后，下发产线次日开始排单（如货期紧急请于客服反应）交期详情需与客服沟通确认（交期不包含节假日）生产中订单需要保证资料准确性，完整性(具体如下资料)：</li>
        <li>1）BOM清单（XLS）；</li>
        <li>2）位号图纸（pdf，标识清楚位置号、极性标识）；</li>
        <li>3）坐标文件（TXT, XLS,CVS）；</li>
        <li>4）PCB、Gerber文件；</li>
        <li>5）特殊工艺要求、三防漆涂刷要求、钢网开口要求（word,xls）</li>
        <li><span style="color:red;">注：</span>如生产资料/工艺变更等需提供《工程变更通知单》与客服确认。</li>
    </ul>
    <ul>
        <li><h2>三．关于物料备损原则</h2></li>
        <li>
            <img style="width: 100%;" src="./img/smt_material_20230913.png"/>
        </li>
    </ul>
    <ul>
        <li><h2>四．关于客供物料</h2></li>
        <li>
            <span>1.</span>
            <em>订单配料请按物料备损原则作业(如上第三点)，因客供料数量未按备损要求提供而造成缺料的我司不另行通知您的情况下空贴出货，<strong style="color:red;">套料不足而生产紧急，需空贴或空插件的请客户下达书面指令明确提出空贴要求。因此造成的空贴物料</strong>我司不负责免费补料，<strong style="color:red;">后续如需补焊缺少的物料，请与您的客服专员沟通，我司提供有偿协同服务。</strong></em>
        </li>
        <li>
            <span>2.</span>
            <em>多个订单的物料，请按订单分开并单独包装，符合BOM一致性，且每个小包装外须有物料名称标识及内置物料清单（<span style="color:red;">详</span>见附件一），以便我司正确快速清点物料；</em>
        </li>
        <li>
            <span>3.</span>
            <em>贴片物料要求编带包装，如果是管装或散料，会收取每种物料100元以上不等(按实际编带工时计算)编带费用。</em>
        </li>
        <li>
            <span>4.</span>
            <em>封装小于等于SOT-23（包括SOT-23/1206/0805/0603/0402/0201）的物料，电阻、电容、二三极管等，每种物料只能有一节；我司不承担因客户原因将多节料自己接料到同一型号物料包装造成的错料问题。</em>
        </li>
        <li>
            <span>5.</span>
            <em>编带物料不能有料带褶皱影响机器贴装、且会增加物料损耗。不能使用透明胶等粘性和残留物多的材料包裹物料，造成物料损耗和机器无法贴装，建议使用美纹胶。我司不承担因以上原因和来料爆带造成的物料损耗。</em>
        </li>
        <li>
            <span>6.</span>
            <em>IC类、引脚类物料注意包装保护，不能直接散装，不能造成物料损坏、引脚变形，我司不承担由此造成的品质不良及物料损坏且将拒收处理。</em>
        </li>
        <li>
            <span>7.</span>
            <em>贵重芯片请使用托盘及真空包装，因包装不当造成的物料损耗、变形，我司不承担责任。我司安排对贵重芯片进行烘烤作业，经过烘烤作业的贵重芯片，我司不承担非焊接原因造成的产品不良及物料损坏。</em>
        </li>
        <li>
            <span>8.</span>
            发货及包装，请贵司发货请注意以下需求（祥见附件二）。
            贵重物料、PCB等较重物料，装箱前请在纸箱四个面使用泡棉防护，避免物流搬运、运输过程中造成的损坏；我司不承担物流原因造成的损失；
            如从贵司发货，请您必须在外箱上注明完整SMT订单编号，如TK17080100001（例）；
            如从第三方平台发货或补料，收件人必须在姓名后增加订单号后5位，如XXX（12345）；
            我司的收件人姓名，请填写我司服务贵司的客服人员名字，便于我司收货区分、沟通；
            发货后请及时将快递单号告知对应客服人员。
        </li>
        <li>
            <span>9.</span>
            <em>余料处理。请贵司严格按以上备损要求提供客供料，原则上我司不接受多余物料退料，如有造成余料，我司将不做清点与产品出货一并退回，我司不对超发多余物料承担任何责任。</em>
        </li>
        <li>
            <span>10.</span>
            <em>随寄货物。贵司从第三方平台或供应商发货给我司的物料、PCB等，重量不能超过5公斤，尺寸不能超过50*38*25CM，超过我司会采取快递或物流到付的方式发给贵司。</em>
        </li>
        <li>
            <span>11.</span>
            <em>同一客户多个订单的客供物料需分开包装，并按备损要求备料，不可一并发料，将会造成总备损数不足导致订单无法完全贴装出货，且影响交付时间。</em>
        </li>
        <li>
            <span>12.</span>
            <em>客供料齐料时间需与订单报价时代购料的承诺齐料时间同步，否则我司将以客供料到料后所有物料异常处理完毕的时间为订单齐料时间。</em>
        </li>
        <li>
            <span>13.</span>
            <em>BOM清单内如有特殊管控，市场交期紧张或市场价格昂贵的特殊物料请在下订单时备注，我司会专门为客户重点管控，如客户未提出需求，我司将以正常物料分类管理，我司不承担正常损耗标准以内的物料损耗费用。</em>
        </li>
        <li>
            <span>14.</span>
            <em>客供料请保证来料品质安全可靠（包含但不限于在材料有效期内、标识与实物一致性、可焊接性及ESD/MSD敏感性--使用真空防潮密封包装、及元器件防护包装—防止物料脱落散乱而发生混料或变形等），确保材料安全而无明显乃至潜在损伤影响贵司产品品质。</em>
        </li>
        <li>
            <span style="color:red;">15.</span>
            <em style="color:red;">客供PCB为拼板可能含有打叉板，其打㕚板种类组合不超过2种，且打叉板数量对应订单数量总占比不得超过5%。超过此标准时我司生产极为复杂耗时，将延期交付时间并产生额外特殊工程费用。</em>
        </li>
    </ul>
    <ul>
        <li><h2>五．关于商务订单报价外的费用及工时费用</h2></li>
        <li>
            <span>1.</span>
            <em>下单后客户通知订单分两次上线的按系统平台报价费用减去钢网费用，即拆分成两个订单分别报价，且正常物料备损需按两个订单的备损要求来计算，不足备损的需客户补足或空贴出货。</em>
        </li>
        <li>
            <span>2.</span>
            <em>订单不齐料，要求上线先空贴、再后补的，补料费用按工时费计算（45元/小时），不足100元的按最低100元/单计算。</em>
        </li>
        <li>
            <span>3.</span>
            <em>因客户来料不良、及设计缺陷需要维修的按45元工时费用收取，不足100元的按最低100元/单收取。</em>
        </li>
        <li>
            <span>4.</span>
            <em>特殊工艺费。</em>
        </li>
        <li>a.PCB拼板后尺寸小于50*50MM的，按每单加收300元工程费计算；</li>
        <li>b.PCB板上无MRAK点，也没有通孔可以做基准点的，按每单加收300元工程费计算;</li>
        <li>c.无板边（PCB板边缘上有贴片物料，无法机器贴片的）,按每单加收300元工程费计算；</li>
        <li>d.PCB尺寸超过400mm*340mm，按每单加收300元工程费计算；</li>
        <li>e.大面积铜条需要上锡的，需要评估加收焊锡材料成本及人工成本，进行报价收费。</li>
        <li>
            <span>5.</span>
            <em>物料需要加工。如来料与焊盘不匹配需要剪脚、插件与插件孔不稳合需要掰脚、组装散热片、扭螺丝、打胶、物料需要组合的等额外工艺，将按45元/小时的工时费用收取。</em>
        </li>
        <li>
            <span>6.</span>
            <em>需要测试、烧录、组装的工艺，需要先沟通协商一致后，报价按45元/小时的工时费用收取，其相关治工具辅助治具由客户提供或我司代购(客户付费)。</em>
        </li>
        <li style="color:red;">
            <span>7.</span>
            <em>如果已经上线生产的订单如因贵司问题造成且需在线等待时，我司将会以600元/H收取停线费用。</em>
        </li>
        <li style="color:red;">
            <span>8.</span>
            <em>当PCB材质是软板或/软硬结合板、或拼板尺寸小于50*50mm，或者外形尺寸不规则无法通过传板设备、或贴装元器件靠近板边5mm内且无板边无法正常生产之情况时，需客户付费由我司代为制作相对应的工装夹治具满足生产需求，制作治工具的周期为2-3天，正常交付时间将顺延2-3天。</em>
        </li>
        <li>**以上正常订单平台报价没有包含的部分，都需商务报价确认后<span style="color:red;display:inline-block;width:10px;">再</span>安排生产作业。</li>
    </ul>
    <ul>
        <li><h2>六．其他</h2></li>  
        <li>
            <span>1.</span>
            <em>本用户协议是您在我司官网阅读并经您勾选确认后生效。</em>
        </li>
        <li>
            <span>2.</span>
            <em>本用户协议适用中华人民共和国法律。在本协议履行期间，如发生争议，双方应本着友好协商的方式处理，如协商不成，任何一方均有权向深圳市福田区人民法院提起诉讼。</em>
        </li>            
    </ul>
    <ul>
        <li><h2>七．附件</h2></li>  
        <li style="display:flex; justify-content: space-between;">
            <div>
                <p style="text-align:center; margin-bottom:6px;">附件一（装箱清单）</p>
                <p><img style="width:330px;" src="./img/f1.png"/></p>
            </div>
            <div>
                <p style="text-align:center; margin-bottom:6px;">附件二（客供料包装规范示例）</p>
                <p><img style="width:378px;" src="./img/f2.png"/></p>
            </div>
        </li>         
    </ul>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.agreement-text {
  color: #5f5f5f !important;
  strong {
    font-weight: normal;
  }
  em {
    font-style: normal;
  }
  h1 {
    padding-right: 10px;
  }
  h2 {
    font-weight: 700;
  }
  ul {
    list-style-type: none !important;
    margin: 0 !important;
    padding: 0 !important;
    li {
      margin-top: 10px;
      text-align: justify;
      padding-right: 10px;
    }
  }
  table td, table th {
    border: 1px solid #ccc;
    padding: 10px;
    line-height: 24px;
  }
}
</style>