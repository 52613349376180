<template>
  <div class="submit-container">
    <OrdersSubmit parentSite="pcb" />
  </div>
</template>

<script>
import OrdersSubmit from '@@/views/submit/index'
export default {
  name: 'ordersSubmit',
  components: {
    OrdersSubmit
  }
}
</script>

<style lang="scss" scoped>
</style>
